import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect } from "react";
import { useFetch } from "../../Hooks/CustomHooks";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import Map1 from "../../Components/img/google-map.png";
import Map2 from "../../Components/img/google-map-2.png";

const UpdateProfile = () => {
  const [country_uuid, setCountryId] = useState(null);
  const [token, setToken] = useState(null);
  const [state, setState] = useState(null);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    if (tk) {
      axios
        .get(
          `${process.env.REACT_APP_Base_url}/company/view_company_details/`,
          {
            headers: {
              "X-COMPANY-USER-AUTH": tk,
            },
          }
        )
        .then((resp) => {
          console.log(resp.data.company_details);
          setState(resp.data.company_details);
        });
    }

    axios
      .get(`${process.env.REACT_APP_Base_url}/setup/get_countries/`)
      .then((res) => {
        const countryid = res.data.country[0].country_id;
        setCountryId(countryid);
      })
      .catch(console.log);

    setToken(sessionStorage.getItem("token"));
  }, []);

  const [response] = useFetch("/setup/get_industries/");
  const industryList = response.industries;

  const onFileChange = (e) => {
    setState((state) => {
      return { ...state, company_logo: e.target.files[0] };
    });
  };

  const SubmitForm = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", state.company_name);
    formData.append("address", state.address);
    formData.append("phone", state.phone_number);
    formData.append("city", state.city);
    formData.append("gps", state.gps);
    formData.append("location", state.location);
    formData.append("background", state.background);
    formData.append("industry_uuid", state.industry.industry_id);
    formData.append("website", state.website);
    formData.append("country_uuid", country_uuid);
    formData.append("facebook", state.social_media.facebook);
    formData.append("instagram", state.social_media.instagram);
    formData.append("twitter", state.social_media.twitter);
    formData.append("google_map_location", state.google_map_location);
    formData.append("momo_number", " ");
    formData.append("momo_acct_name", " ");
    formData.append("map_id", "google");

    formData.append("company_logo", state.company_logo);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    if (token) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/company/update_company_profile/`,
          formData,
          {
            headers: {
              "X-COMPANY-USER-AUTH": token,
            },
          }
        )
        .then((res3) => {
          if (res3.data.status === 200) {
            toast.success(res3.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
              window.location.replace("/profile");
            }, 5000);
          } else {
            toast.success(res3.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
              window.location.replace("/profile");
            }, 5000);
          }
        })
        .catch((err) => {
          toast.error(err);
          console.log(err);
        });
    }
  };

  // const displayMessage = () => {
  // toast.success("Loading !!!", {
  //   position: toast.POSITION.TOP_CENTER,
  // });
  // var x = document.getElementById("myDIV");
  // x.style.display = "none";
  // };

  return (
    <>
      <ToastContainer />
      <div className="page">
        <Header />

        <div className="page-content d-flex align-items-stretch">
          {/* Side Navbar */}
          <Sidebar />

          <div className="content-inner">
            {/* Page Header*/}
            <header className="page-header">
              <div className="container-fluid">
                <h2 className="no-margin-bottom">Update Profile</h2>
              </div>
            </header>
            {/* Breadcrumb*/}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Update Profile</li>
              </ul>
            </div>
            {/* Forms Section*/}
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-close">
                        <div className="dropdown">
                          <button
                            type="button"
                            id="closeCard5"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="dropdown-toggle">
                            <i className="fa fa-ellipsis-v" />
                          </button>
                          <div
                            aria-labelledby="closeCard5"
                            className="dropdown-menu dropdown-menu-right has-shadow"></div>
                        </div>
                      </div>

                      <div className="card-header d-flex align-items-center">
                        <h3 className="h4">Update Profile Details</h3>
                      </div>

                      {state && (
                        <div className="card-body">
                          <form
                            className="form-horizontal"
                            onSubmit={SubmitForm}>
                            <div className="form-group row">
                              <label className="col-sm-3 form-control-label">
                                Corporate Name
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={state.company_name}
                                  onChange={(e) =>
                                    setState((state) => {
                                      return {
                                        ...state,
                                        company_name: e.target.value,
                                      };
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-3 form-control-label">
                                Address
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={state.address}
                                  onChange={(e) =>
                                    setState((state) => {
                                      return {
                                        ...state,
                                        address: e.target.value,
                                      };
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-3 form-control-label">
                                City
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={state.city}
                                  onChange={(e) =>
                                    setState((state) => {
                                      return { ...state, city: e.target.value };
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-3 form-control-label">
                                Location
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={state.location}
                                  onChange={(e) =>
                                    setState((state) => {
                                      return {
                                        ...state,
                                        location: e.target.value,
                                      };
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-3 form-control-label">
                                GPS Address Number
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={state.gps}
                                  onChange={(e) =>
                                    setState((state) => {
                                      return { ...state, gps: e.target.value };
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-3 form-control-label">
                                Contact Number
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="tel"
                                  maxLength={10}
                                  minLength={10}
                                  className="form-control"
                                  placeholder={state.phone_number}
                                  onChange={(e) =>
                                    setState((state) => {
                                      return {
                                        ...state,
                                        phone_number: e.target.value,
                                      };
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="line"> </div>
                            <div className="row">
                              <label className="col-sm-3 form-control-label">
                                About Company{" "}
                              </label>
                              <div className="col-sm-9">
                                <div className="form-group">
                                  <textarea
                                    className="form-control"
                                    rows="3"
                                    placeholder={state.background}
                                    onChange={(e) =>
                                      setState((state) => {
                                        return {
                                          ...state,
                                          background: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            {industryList && (
                              <div className="form-group row">
                                <label
                                  htmlFor="industry"
                                  className="col-sm-3 form-control-label">
                                  Industry
                                </label>
                                <div className="col-sm-9">
                                  <select
                                    name="industry"
                                    className="form-control mb-3"
                                    style={{ width: "auto" }}
                                    onChange={(e) =>
                                      setState((state) => {
                                        return {
                                          ...state,
                                          industry: {
                                            ...state.industry,
                                            industry_id: e.target.value,
                                          },
                                        };
                                      })
                                    }>
                                    <option>Select an industry</option>
                                    {industryList.map((item, key) => {
                                      return (
                                        <option
                                          value={item.industry_id}
                                          key={key}>
                                          {item.industry_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {/* } */}
                                </div>
                              </div>
                            )}{" "}
                            <hr />
                            <div className="form-group row">
                              <label className="col-sm-3 form-control-label">
                                website url
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="url"
                                  className="form-control"
                                  placeholder={state.website}
                                  onChange={(e) =>
                                    setState((state) => {
                                      return {
                                        ...state,
                                        website: e.target.value,
                                      };
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-3 form-control-label">
                                Facebook url
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="url"
                                  className="form-control"
                                  placeholder={state.social_media.facebook}
                                  onChange={(e) =>
                                    setState((state) => {
                                      return {
                                        ...state,
                                        social_media: {
                                          ...state.social_media,
                                          facebook: e.target.value,
                                        },
                                      };
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-3 form-control-label">
                                Instagram url
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="url"
                                  className="form-control"
                                  placeholder={state.social_media.instagram}
                                  onChange={(e) =>
                                    setState((state) => {
                                      return {
                                        ...state,
                                        social_media: {
                                          ...state.social_media,
                                          instagram: e.target.value,
                                        },
                                      };
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-3 form-control-label">
                                Twitter url
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="url"
                                  className="form-control"
                                  placeholder={state.social_media.twitter}
                                  onChange={(e) =>
                                    setState((state) => {
                                      return {
                                        ...state,
                                        social_media: {
                                          ...state.social_media,
                                          twitter: e.target.value,
                                        },
                                      };
                                    })
                                  }
                                />
                              </div>
                            </div>
                            {/*  Helper */}
                            <div className="form-group row py-3 px-4">
                              <div>
                                {/* Button trigger modal */}
                                <button
                                  type="button"
                                  className="btn btn-warning text-white"
                                  data-toggle="modal"
                                  data-target="#exampleModal1">
                                  Helper
                                </button>
                                {/* Modal */}
                                <div
                                  className="modal fade"
                                  id="exampleModal1"
                                  tabIndex={-1}
                                  aria-labelledby="exampleModalLabel"
                                  aria-hidden="true">
                                  <div className="modal-dialog modal-dialog-scrollable">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5
                                          className="modal-title"
                                          id="exampleModalLabel">
                                          To add URL
                                        </h5>
                                        <button
                                          type="button"
                                          className="close"
                                          data-dismiss="modal"
                                          aria-label="Close">
                                          <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <p>1. Visit your page/account</p>
                                        <p>
                                          2. Click on the search bar to copy the
                                          url ..eg (https://example.com)
                                        </p>
                                        <p>
                                          3. Complete the process by pasting the
                                          copied link in the text-box on the
                                          profile update
                                        </p>
                                      </div>
                                      <div className="modal-footer">
                                        <button
                                          type="button"
                                          className="btn btn-secondary"
                                          data-dismiss="modal">
                                          Close
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* End of Helper */}
                            {/* <div className="line"> </div>

                                         <div className="form-group row">
                                         <label className="col-sm-3 form-control-label">Momo Number</label>
                                         <div className="col-sm-9">
                                         <input maxLength={12} minLength={10}  type="tel"  className="input-material" placeholder={state.momo_number} onChange={e => setState((state) => {return {...state,momo_number: e.target.value}}) } />
                                         </div>
                                        </div> */}
                            {/* <div className="line"> </div>

                                        <div className="form-group row">
                                         <label className="col-sm-3 form-control-label">Account Name</label>
                                         <div className="col-sm-9">
                                         <input  type="text"  className="input-material"  placeholder={state.momo_acct_name} onChange={e => setState((state) => {return {...state,momo_acct_name: e.target.value}}) } />
                                         </div>
                                        </div> */}
                            <div className="line"> </div>
                            <div className="form-group row">
                              <label className="col-sm-3 form-control-label">
                                Embed google map location
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={state.google_map_location}
                                  onChange={(e) =>
                                    setState((state) => {
                                      return {
                                        ...state,
                                        google_map_location: e.target.value,
                                      };
                                    })
                                  }
                                />
                              </div>
                            </div>
                            {/* Map Helper */}
                            <div className="form-group row py-3 px-4">
                              <div>
                                {/* Button trigger modal */}
                                <button
                                  type="button"
                                  className="btn btn-warning text-white"
                                  data-toggle="modal"
                                  data-target="#exampleModal">
                                  Map helper
                                </button>
                                {/* Modal */}
                                <div
                                  className="modal fade"
                                  id="exampleModal"
                                  tabIndex={-1}
                                  aria-labelledby="exampleModalLabel"
                                  aria-hidden="true">
                                  <div className="modal-dialog modal-dialog-scrollable">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5
                                          className="modal-title"
                                          id="exampleModalLabel">
                                          To add google map location
                                        </h5>
                                        <button
                                          type="button"
                                          className="close"
                                          data-dismiss="modal"
                                          aria-label="Close">
                                          <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <p>
                                          1. Visit{" "}
                                          <span>
                                            <a
                                              href="https://www.google.com.gh/maps"
                                              target="_blank"
                                              rel="noreferrer">
                                              google map
                                            </a>
                                          </span>
                                        </p>
                                        <p>
                                          2. Search for your location in the top
                                          left corner text box provided
                                        </p>
                                        <p>
                                          3. Click on share as shown in the
                                          image.
                                        </p>
                                        <p>
                                          <img
                                            className="img-fluid img-thumbnail"
                                            src={Map1}
                                            alt="google share"
                                          />
                                        </p>
                                        <p>
                                          4. Click on Embed a map as indicated
                                          in the image by the arrow
                                        </p>
                                        <p>
                                          5. Now click on COPY HTML to get the
                                          link
                                        </p>
                                        <p>
                                          6. Complete the process by using the
                                          copied link in your Google Map
                                          text-box on the profile update
                                        </p>
                                        <p>
                                          <img
                                            className="img-fluid img-thumbnail"
                                            src={Map2}
                                            alt="google share"
                                          />
                                        </p>
                                      </div>
                                      <div className="modal-footer">
                                        <button
                                          type="button"
                                          className="btn btn-secondary"
                                          data-dismiss="modal">
                                          Close
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Map Helper */}
                            <div className="line" />
                            <div className="form-group row">
                              <label
                                htmlFor="fileInput"
                                className="col-sm-3 form-control-label">
                                Upload logo
                              </label>
                              <div className="col-sm-9">
                                <input
                                  id="fileInput"
                                  type="file"
                                  accept="image/*"
                                  className="form-control-file"
                                  onChange={onFileChange}
                                />
                              </div>
                            </div>
                            <hr />
                            <div className="line" />
                            <div className="form-group row">
                              <div className="col-sm-4 offset-sm-3">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  name="submit"
                                  value="upload">
                                  Save changes
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Page Footer*/}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateProfile;

import React from "react";
import "primeicons/primeicons.css";
import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Task from "./Pages/Task/Task";
import Login from "./Pages/Login/Login";
import ErrorPage from "./Pages/404/404";
import ProtectedRoute from "./ProtectedRoute";
import Payment from "./Pages/Payment/Payment";
import Profile from "./Pages/Profile/Profile";
import TaskDetails from "./Pages/Task/TaskDetails";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Activation from "./Pages/Activation/Activation";
import PayoutDetails from "./Pages/Task/PayoutDetails";
import Registration from "./Pages/Registration/Register";
import ResetPassword from "./Pages/Password/ResetPassword";
import ChangePassword from "./Pages/Password/ChangePassword";
import ForgotPassword from "./Pages/Password/ForgotPassword";
import ActiveBoost from "./Pages/Ads Management/ActiveBoost";
import Notification from "./Pages/Notifications/Notification";
import PaymentServices from "./Pages/Payment/PaymentServices";
import UpdateProfile from "./Pages/Update Profile/UpdateProfile";
import TermsAndConditions from "./Pages/Terms/TermsAndConditions";
import AccountDetails from "./Pages/Account Details/AccountDetails";
import PendingDelivery from "./Pages/Pending Delivery/PendingDelivery";
import NotificationDetails from "./Pages/Notifications/NotificationDetails";
import ConfirmationPaymentDetails from "./Pages/Task/ConfirmationPaymentDetails";
import ProductPost from "./Pages/Ads Management/Products Management/ProductPost";
import ServicesPost from "./Pages/Ads Management/Services Management/ServicesPost";
import ActiveServices from "./Pages/Ads Management/Services Management/ActiveServices";
import ActiveProducts from "./Pages/Ads Management/Products Management/ActiveProducts";
import DraftedProduct from "./Pages/Ads Management/Products Management/DraftedProduct";
import DraftedServices from "./Pages/Ads Management/Services Management/DraftedServices";
import RetiredServices from "./Pages/Ads Management/Services Management/RetiredServices";
import RetiredProducts from "./Pages/Ads Management/Products Management/RetiredProducts";
import DraftedProductEdit from "./Pages/Ads Management/Products Management/DraftedProductEdit";
import DraftedServiceEdit from "./Pages/Ads Management/Services Management/DraftedServiceEdit";
import MessageDetails from "./Pages/Task/MessageDetails";
import Post from "./Pages/Ads Management/Products Management/Post";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/activation" element={<Activation />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/terms_&_conditions" element={<TermsAndConditions />} />

        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/notifications" element={<Notification />} />
          <Route
            path="/notification_details"
            element={<NotificationDetails />}
          />
          <Route path="/task" element={<Task />} />
          <Route path="/task_details" element={<TaskDetails />} />
          <Route path="/message_details" element={<MessageDetails />} />
          <Route path="/payout_details" element={<PayoutDetails />} />
          <Route
            path="/confirmation_payment_details"
            element={<ConfirmationPaymentDetails />}
          />

          <Route path="/pending_delivery" element={<PendingDelivery />} />
          <Route path="/update_profile" element={<UpdateProfile />} />
          <Route path="/change_password" element={<ChangePassword />} />
          <Route path="/post" element={<Post />} />
          <Route path="/product_post" element={<ProductPost />} />
          <Route path="/drafted_product" element={<DraftedProduct />} />
          <Route path="/active_products" element={<ActiveProducts />} />
          <Route path="/retired_products" element={<RetiredProducts />} />
          <Route
            path="/drafted_product_edit"
            element={<DraftedProductEdit />}
          />

          <Route path="/services_post" element={<ServicesPost />} />
          <Route path="/drafted_services" element={<DraftedServices />} />
          <Route path="/active_services" element={<ActiveServices />} />
          <Route path="/retired_services" element={<RetiredServices />} />
          <Route
            path="/drafted_service_edit"
            element={<DraftedServiceEdit />}
          />

          <Route path="/active_boost" element={<ActiveBoost />} />

          <Route path="/payment" element={<Payment />} />
          <Route path="/account_details" element={<AccountDetails />} />
          <Route path="/payment_services" element={<PaymentServices />} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;

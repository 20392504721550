import React from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Sidebar from "../../Components/Sidebar/Sidebar";
import AccountForm from "../../Components/Account Details/AccountForm";

const AccountDetails = () => {
  return (
    <>
      <div className="page">
        <Header />

        <div className="page-content d-flex align-items-stretch">
          {/* Side Navbar */}
          <Sidebar />

          <div className="content-inner">
            <header className="page-header">
              <div className="container-fluid">
                <h2 className="no-margin-bottom">Payment Details Profile</h2>
              </div>
            </header>

            {/* Forms Section*/}
            <AccountForm />
            {/* Page Footer*/}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDetails;

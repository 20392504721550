import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { Link, useLocation } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";

const PayoutDetails = () => {
  const location = useLocation();
  const [details, setDetails] = useState({
    messageDetails: null,
  });

  console.log(location);

  useEffect(() => {
    if (location.state) {
      setDetails((details) => {
        return { ...details, messageDetails: location.state.pending };
      });
    }
  }, [location.state]);

  return (
    <div className="page">
      {/* Main Navbar*/}
      <Header />

      <div className="page-content d-flex align-items-stretch">
        {/* Side Navbar */}
        <Sidebar />

        <div className="content-inner">
          {/* Page Header*/}
          <header className="page-header">
            <div className="container-fluid">
              <h2 className="no-margin-bottom">Payout Dashboard</h2>
            </div>
          </header>

          {/* Updates Section  */}
          <section className="updates no-padding-top">
            <div className="container-fluid">
              <div className="row">
                {/* Daily Feeds */}
                <div className="col-lg-12 pt-5">
                  <div className="daily-feeds card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard7"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard7"
                          className="dropdown-menu dropdown-menu-right has-shadow"></div>
                      </div>
                    </div>
                    <div className="card-header">
                      <h3 className="h4">Payout Details</h3>
                    </div>
                    <div className="card-body no-padding">
                      {/* Item*/}

                      <div className="item">
                        <div className="feed d-flex justify-content-between">
                          <div className="feed-body d-flex justify-content-between">
                            <Link to="#" className="feed-profile">
                              <img
                                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                alt="person"
                                className="img-fluid rounded-circle"
                              />
                            </Link>
                            {details.messageDetails && (
                              <div className="content">
                                <div className="h5 p-2">
                                  <span>
                                    <b className="h4">
                                      {details.messageDetails.title}
                                    </b>
                                  </span>
                                </div>

                                <div className="p-2 h5">
                                  <span>
                                    <b className="h4">
                                      {details.messageDetails.message}
                                    </b>
                                  </span>
                                </div>

                                <div className="h5 p-2">
                                  <span>
                                    ----&gt;{" "}
                                    <b className="h4 px-3">
                                      {details.messageDetails.date_created}
                                    </b>
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Page Footer*/}

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default PayoutDetails;

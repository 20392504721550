import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

const AccountForm = () => {
  const [token, setToken] = useState(null);
  const [state, setState] = useState(null);

  useEffect(() => {
    setToken(sessionStorage.getItem("token"));

    if (token) {
      axios
        .get(
          `${process.env.REACT_APP_Base_url}/payment/payment_account_details/`,
          {
            headers: {
              "X-COMPANY-USER-AUTH": token,
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          setState(response.data.payment_account_details);
        });
    }
  }, [token]);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (token) {
      await axios
        .post(
          `${process.env.REACT_APP_Base_url}/payment/add_payment_account/`,
          {
            account_name: state.account_name,
            account_number: state.account_number,
            network: state.network,
            account_type: "",
            account_code: state.network,
          },
          {
            headers: {
              "X-COMPANY-USER-AUTH": token,
            },
          }
        )
        .then((resp) => {
          // console.log(resp);
          if (resp.data.status === 200) {
            // alert(resp.data.message);
            toast.success(resp.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
              window.location.replace("/profile");
            }, 5000);
          } else {
            // alert(resp.data.message);
            toast.success(resp.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
              window.location.replace("/profile");
            }, 5000);
          }
        })
        .catch((err) => {
          toast.error(err);
          console.log(err);
        });
    }
  };

  const displayMessage = () => {
    toast.warn("Loading !!!", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1000,
    });
    // var x = document.getElementById("myDIV");
    // x.style.display = "none";
  };

  return (
    <>
      <ToastContainer />

      <section className="forms" style={{ padding: "20px 0" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header d-flex align-items-center">
                  <h3 className="h4">
                    {" "}
                    NB: Provide us the details to which you want to receive your
                    payment{" "}
                  </h3>
                </div>

                <div className="card-body">
                  <form className="form-horizontal" onSubmit={submitHandler}>
                    <div>
                      <div className="container-fluid">
                        <h3 className="no-margin-bottom">
                          Mobile Money Payment Details{" "}
                        </h3>
                      </div>
                      <br />
                      <br />

                      {state && (
                        <div className="form-group row pt-2">
                          <label className="col-sm-3 form-control-label">
                            {" "}
                            Mobile Network
                          </label>
                          <div className="col-sm-9">
                            <select
                              name="delivery"
                              value={state.network}
                              className="form-control mb-3"
                              onChange={(e) =>
                                setState((state) => {
                                  return {
                                    ...state,
                                    network: e.target.value,
                                  };
                                })
                              }>
                              <option>---</option>
                              <option>mtn</option>
                              <option>vodafone</option>
                              <option> tigo</option>
                            </select>
                          </div>
                        </div>
                      )}

                      {state && (
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Mobile Money Number
                          </label>
                          <div className="col-sm-9">
                            <input
                              maxLength={10}
                              minLength={10}
                              type="tel"
                              className="input-material"
                              placeholder={state.account_number}
                              onChange={(e) =>
                                setState((state) => {
                                  return {
                                    ...state,
                                    account_number: e.target.value,
                                  };
                                })
                              }
                            />
                          </div>
                        </div>
                      )}
                      <br />

                      {state && (
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Mobile Money Account Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="input-material"
                              placeholder={state.account_name}
                              onChange={(e) =>
                                setState((state) => {
                                  return {
                                    ...state,
                                    account_name: e.target.value,
                                  };
                                })
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="line" />
                    <div className="form-group row">
                      <div className="col-sm-4 offset-sm-3">
                        <button
                          id="myDIV"
                          onClick={displayMessage}
                          type="submit"
                          className="btn btn-primary"
                          name="submit"
                          value="upload">
                          Save changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountForm;

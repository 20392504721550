import React from "react";
import { useFetch } from "../../Hooks/CustomHooks";

const Analytics = () => {
  // custom hook to fetch data from the server and store it in the dashboardDetails variable
  const [response] = useFetch("/company/dashboard/");
  // console.log(response);
  const dashBoardDetails = response.dashboard_data;

  return (
    <>
      {dashBoardDetails && dashBoardDetails.inactive_services !== 0 ? (
        <section className="dashboard-counts no-padding-bottom">
          <div className="container-fluid">
            <div className="row bg-white has-shadow">
              {/* Item */}
              <div className="col-xl-3 col-sm-6">
                {dashBoardDetails && (
                  <div className="item d-flex align-items-center">
                    <div className="icon bg-violet">
                      <i className="icon-grid" />
                    </div>
                    <div className="title">
                      <span>
                        Active
                        <br />
                        Products
                      </span>
                      <div className="progress">
                        <div
                          role="progressbar"
                          style={{ width: "100%", height: "4px" }}
                          aria-valuenow={90}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          className="progress-bar bg-violet"
                        />
                      </div>
                    </div>
                    <div className="number">
                      <strong>{dashBoardDetails.total_products}</strong>
                    </div>
                  </div>
                )}
              </div>
              {/* Item */}

              {/* Item */}
              <div className="col-xl-3 col-sm-6">
                {dashBoardDetails && (
                  <div className="item d-flex align-items-center">
                    <div className="icon bg-green">
                      <i className="icon-bill" />
                    </div>
                    <div className="title">
                      <span>
                        Active
                        <br />
                        Services
                      </span>
                      <div className="progress">
                        <div
                          role="progressbar"
                          style={{ width: "100%", height: "4px" }}
                          aria-valuenow={40}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          className="progress-bar bg-green"
                        />
                      </div>
                    </div>
                    <div className="number">
                      <strong>{dashBoardDetails.active_services}</strong>
                    </div>
                  </div>
                )}
              </div>
              {/* Item */}
              <div className="col-xl-3 col-sm-6">
                {dashBoardDetails && (
                  <div className="item d-flex align-items-center">
                    <div className="icon bg-orange">
                      <i className="icon-check" />
                    </div>
                    <div className="title">
                      <span>
                        Inactive
                        <br />
                        Products
                      </span>
                      <div className="progress">
                        <div
                          role="progressbar"
                          style={{ width: "100%", height: "4px" }}
                          aria-valuenow={dashBoardDetails.inactive_products}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          className="progress-bar bg-orange"
                        />
                      </div>
                    </div>
                    <div className="number">
                      <strong>{dashBoardDetails.inactive_products}</strong>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-xl-3 col-sm-6">
                {dashBoardDetails && (
                  <div className="item d-flex align-items-center">
                    <div className="icon bg-blue">
                      <i className="icon-presentation" />
                    </div>
                    <div className="title">
                      <span>
                        Inactive
                        <br />
                        Services
                      </span>
                      <div className="progress">
                        <div
                          role="progressbar"
                          style={{ width: "100%", height: "4px" }}
                          aria-valuenow={dashBoardDetails.inactive_services}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          className="progress-bar bg-blue"
                        />
                      </div>
                    </div>
                    <div className="number">
                      <strong>{dashBoardDetails.inactive_services}</strong>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="dashboard-counts no-padding-bottom">
          <div className="container-fluid">
            <div className="row bg-white has-shadow">
              {/* Item */}
              <div className="col-xl-4 col-sm-6">
                {dashBoardDetails && (
                  <div className="item d-flex align-items-center">
                    <div className="icon bg-violet">
                      <i className="icon-grid" />
                    </div>
                    <div className="title">
                      <span>
                        Total
                        <br />
                        Products
                      </span>
                      <div className="progress">
                        <div
                          role="progressbar"
                          style={{ width: "25%", height: "4px" }}
                          aria-valuenow={90}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          className="progress-bar bg-violet"
                        />
                      </div>
                    </div>
                    <div className="number">
                      <strong>{dashBoardDetails.total_products}</strong>
                    </div>
                  </div>
                )}
              </div>
              {/* Item */}

              {/* Item */}
              <div className="col-xl-4 col-sm-6">
                {dashBoardDetails && (
                  <div className="item d-flex align-items-center">
                    <div className="icon bg-green">
                      <i className="icon-bill" />
                    </div>
                    <div className="title">
                      <span>
                        Active
                        <br />
                        Posts
                      </span>
                      <div className="progress">
                        <div
                          role="progressbar"
                          style={{ width: "40%", height: "4px" }}
                          aria-valuenow={40}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          className="progress-bar bg-green"
                        />
                      </div>
                    </div>
                    <div className="number">
                      <strong>{dashBoardDetails.active_products}</strong>
                    </div>
                  </div>
                )}
              </div>
              {/* Item */}
              <div className="col-xl-4 col-sm-6">
                {dashBoardDetails && (
                  <div className="item d-flex align-items-center">
                    <div className="icon bg-orange">
                      <i className="icon-check" />
                    </div>
                    <div className="title">
                      <span>
                        Retired
                        <br />
                        Posts
                      </span>
                      <div className="progress">
                        <div
                          role="progressbar"
                          style={{ width: "50%", height: "4px" }}
                          aria-valuenow={dashBoardDetails.inactive_products}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          className="progress-bar bg-orange"
                        />
                      </div>
                    </div>
                    <div className="number">
                      <strong>{dashBoardDetails.inactive_products}</strong>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Analytics;

import axios from "axios";
import queryString from "query-string";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer/Footer";

const Activation = () => {
  const { search } = useLocation();
  const { email, profile_id, token } = queryString.parse(search);
  const [display, setDisplay] = useState("");
  console.log(search);

  useEffect(() => {
    if (email && profile_id && token) {
      axios
        .post(`${process.env.REACT_APP_Base_url}/company/activate_account/`, {
          acct_uuid: profile_id,
          email,
          token,
        })
        .then((res) => {
          // console.log(res);
          setDisplay(res.data.message);

          if (res.data.status === 200) {
            // console.log(res);
            alert(res.data.message);
            window.location.replace("/");
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [email, profile_id, token]);

  const handleResend = () => {
    if (email && profile_id) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/company/resend_activation_link/`,
          {
            acct_uuid: profile_id,
            email,
          }
        )
        .then((res) => {
          // console.log(res);
          alert(res.data.message);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("error occured");
    }
  };

  return (
    <div className="page-holder">
      {/* <Header /> */}
      <header className="header">
        <nav className="navbar">
          {/* Search Box*/}
          <div className="search-box">
            <button className="dismiss">
              <i className="icon-close" />
            </button>
            <form id="searchForm" action="#/" role="search">
              <input
                type="search"
                placeholder="What are you looking for..."
                className="form-control"
              />
            </form>
          </div>

          <div className="container-fluid">
            <div className="navbar-holder d-flex align-items-center justify-content-between">
              {/* Navbar Header*/}
              <div className="navbar-header">
                {/* Navbar Brand */}
                <a href="/" className="navbar-brand d-none d-sm-inline-block">
                  <div className="brand-text d-none d-lg-inline-block">
                    <span> </span>
                    <strong>RosmallOnline</strong>
                  </div>
                  <div className="brand-text d-none d-sm-inline-block d-lg-none">
                    <strong>RosmallOnline</strong>
                  </div>
                </a>
                {/* Toggle Button*/}
                <a id="toggle-btn" href="#/" className="menu-btn active">
                  <span />
                  <span />
                  <span />
                </a>
              </div>
              {/* Navbar Menu */}
              <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
                <li className="nav-item dropdown">
                  <Link
                    id="languages"
                    rel="nofollow"
                    data-target="#"
                    to="#/"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="nav-link language dropdown-toggle">
                    <span className="d-none d-sm-inline-block">Profile</span>
                  </Link>
                  <ul aria-labelledby="languages" className="dropdown-menu">
                    <li>
                      <a rel="nofollow" href="/" className="dropdown-item">
                        Login
                      </a>
                    </li>
                    <li>
                      <a
                        rel="nofollow"
                        href="/Register"
                        className="dropdown-item">
                        Registration
                      </a>
                    </li>
                  </ul>
                </li>
                {/* Logout    */}
                <li className="nav-item">
                  <a href="/" className="nav-link logout">
                    {" "}
                    <span className="d-none d-sm-inline">Logout</span>
                    <i className="fa fa-sign-out" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      {/* End of <Header /> */}

      <div className="container py-5" style={{ height: "60vh" }}>
        <h1 className="text-center">Account Activation </h1>
        <hr />
        <div className="" style={{ marginTop: "100px" }}>
          {display &&
          display ===
            "Link Has Expired. Click On The Link Below to Resend Link" ? (
            <p className="text-center py-3 text-danger">
              <b> {display}</b>
            </p>
          ) : (
            <p className="text-center py-3 text-success">
              <b> {display}</b>
            </p>
          )}

          {display ===
            "Link Has Expired. Click On The Link Below to Resend Link" && (
            <div className="text-center py-3">
              <button className="btn btn-primary" onClick={handleResend}>
                Resend Verification Email
              </button>
            </div>
          )}

          {display === "Account Already Activated" && (
            <div className="text-center py-3">
              <a href="/" className="btn btn-primary">
                Login here
              </a>
            </div>
          )}

          {display === "Account Activated Successfully" && (
            <div className="text-center py-3">
              <a href="/" className="btn btn-primary">
                Login here
              </a>
            </div>
          )}

          {display === "Profile Does Not Exist" && (
            <div className="text-center py-3">
              <a href="/register" className="btn btn-primary">
                Register
              </a>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Activation;

import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../../../Components/Sidebar/Sidebar";

const DraftedServices = () => {
  const [token, setToken] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const [draftedServices, setDraftedServices] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setToken(sessionStorage.getItem("token"));
    setAccountType(sessionStorage.getItem("accountType"));

    if (token) {
      axios
        .get(
          `${process.env.REACT_APP_Base_url}/product/list_pending_services/`,
          {
            headers: {
              "X-COMPANY-USER-AUTH": token,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setDraftedServices(res.data.pending_services);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  const Activate = (id) => {
    console.log(id);
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/product/request_activation/`,
        {
          record_id: id,
          record_name: "service",
        },
        {
          headers: {
            "X-COMPANY-USER-AUTH": token,
          },
        }
      )
      .then((resp) => {
        console.log(resp.data);
        if (accountType === "Business Standard") {
          alert("Please proceed to  make payment for item to be Activated");
          navigate("/payment_services", {
            state: { id: id },
          });
        } else {
          console.log(resp.data);
          if (resp.status === 200) {
            toast.success(resp.data.message);
            setTimeout(() => {
              window.location.reload(true);
            }, 3000);
          }
        }
      })
      .catch((err) => {
        toast.error(err);
        console.log(err);
      });
  };

  const Delete = (id) => {
    console.log(id);
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/product/delete_service/`,
        {
          service_ids: [id],
        },
        {
          headers: {
            "X-COMPANY-USER-AUTH": token,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          // alert(response.data.message);
          toast.error(
            response.data.message,
            {
              position: toast.POSITION.TOP_CENTER,
            },
            { autoClose: 4000 }
          );
          setTimeout(() => {
            window.location.reload(true);
          }, 3000);
        }
      })
      .catch((error) => {
        toast.error(error);
        console.log(error);
      });
  };

  return (
    <>
      <ToastContainer />

      <div className="page">
        <Header />
        <div className="page-content d-flex align-items-stretch">
          {/* Side Navbar */}
          <Sidebar />

          <div className="content-inner">
            {/* Page Header*/}
            <header className="page-header">
              <div className="container-fluid">
                <h2 className="no-margin-bottom">DRAFTED SERVICE DASHBOARD</h2>
              </div>
            </header>
            {/* Updates Section     */}
            <section className="updates no-padding-top">
              <div className="container-fluid">
                <div className="row">
                  {/* Daily Feeds */}
                  <div className="col-lg-12 pt-5">
                    <div className="daily-feeds card">
                      <div className="card-close">
                        <div className="dropdown">
                          <button
                            type="button"
                            id="closeCard7"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="dropdown-toggle">
                            <i className="fa fa-ellipsis-v" />
                          </button>
                          <div
                            aria-labelledby="closeCard7"
                            className="dropdown-menu dropdown-menu-right has-shadow"></div>
                        </div>
                      </div>
                      <div className="card-header">
                        <h3 className="h4">DRAFTED SERVICE LIST</h3>
                      </div>
                      <div className="card-body no-padding">
                        {/* Item*/}
                        {draftedServices &&
                        draftedServices.length !== 0 &&
                        draftedServices.length !== undefined ? (
                          draftedServices.map((services) => {
                            return (
                              <div className="item" key={services.service_uuid}>
                                <div className="feed d-flex justify-content-between">
                                  <div className="feed-body d-flex justify-content-between">
                                    <a href="#/" className="feed-profile">
                                      <img
                                        src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                        alt="person"
                                        className="img-fluid rounded-circle"
                                      />
                                    </a>
                                    <div className="content">
                                      <h5>{services.service_name}</h5>
                                      <br />
                                      <div className="CTAs">
                                        <button
                                          onClick={() =>
                                            Activate(services.service_uuid)
                                          }
                                          className="btn btn-sm btn-success">
                                          Request Activation
                                        </button>
                                        <Link
                                          to="/drafted_service_edit"
                                          state={{ id: services.service_uuid }}
                                          className="btn btn-xs btn-warning m-1 p-1">
                                          <i className="fa fa-pencil-square-o">
                                            {" "}
                                          </i>
                                          Edit
                                        </Link>
                                        <button
                                          onClick={() =>
                                            Delete(services.service_uuid)
                                          }
                                          className="btn btn-sm btn-danger">
                                          <i className="fa fa-trash-o"> </i>
                                          <span
                                            className="px-2"
                                            style={{ color: "#fff" }}>
                                            Delete
                                          </span>{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="date text-right">
                                    <small>{services.date_created}</small>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="text-center pt-4">
                            <p>No records found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Page Footer*/}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default DraftedServices;

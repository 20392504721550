import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../../../Components/Sidebar/Sidebar";

const ServicesPost = () => {
  const [token, setToken] = useState(null);
  const [catList, setCatList] = useState([]);
  const [subcat, setSubCat] = useState([]);
  const [service_name, setServiceName] = useState("");
  const [ref_number, setRefNumber] = useState("");
  const [days_run, setDays] = useState("");
  const [start_date, setStart] = useState("");
  const [description, setDescription] = useState("");
  const [category_id, setCategoryId] = useState("");
  const [sub_category_id, setSubCategoryId] = useState("");
  const [region, setRegion] = useState("");
  const [file, setFile] = useState("");
  const [regionList, setRegionList] = useState(null);
  let formData = new FormData();

  useEffect(() => {
    // List Services  Category
    axios
      .get(`${process.env.REACT_APP_Base_url}/setup/list_service_category/`)
      .then((res) => {
        console.log(res.data);
        setCatList(res.data.service_category);
      })
      .catch((err) => {
        console.log(err);
      });

    setToken(sessionStorage.getItem("token"));

    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_url}/setup/get_regions/`, {
          headers: {
            "X-COMPANY-USER-AUTH": token,
          },
        })
        .then((response) => {
          console.log(response.data);
          setRegionList(response.data.regions);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  const getSubCategory = () => {
    // gettingData from product sub_category_name
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup/list_service_sub_category/`,
        {
          service_cat_uuid: category_id,
        }
      )
      .then((resp) => {
        console.log(resp.data);
        setSubCat(resp.data.service_sub_category);
      })
      .catch((erro) => {
        console.error(erro);
      });
  };

  const onFileChange = (e) => {
    console.log(e.target.files[0]);
    // check if image size is less than 5MB
    if (e.target && e.target.files[0].size < 5000000) {
      setFile(e.target.files[0]);
    } else {
      toast.error("Image size should be less than 5MB");
      return;
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    formData.append("service_name", service_name);
    formData.append("region_id", region);
    formData.append("ref_number", ref_number);
    formData.append("days_run", days_run);
    formData.append("start_date", start_date);
    formData.append("description", description);
    formData.append("category_id", category_id);
    formData.append("service_sub_category_id", sub_category_id);
    formData.append("attached_file", file);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    if (sub_category_id !== "" && category_id !== "") {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/product/post_service/`,
          formData,
          {
            headers: {
              "X-COMPANY-USER-AUTH": token,
            },
          }
        )
        .then((res) => {
          console.log(res);

          if (res.data.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
              window.location.replace("/drafted_services");
            }, 3000);
          } else {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
              window.location.replace("/drafted_services");
            }, 3000);
          }
        })
        .catch((err) => {
          toast.error(err);
          console.log(err);
        });
    } else {
      toast.warn("service category or services subcategory can't be empty");
    }
  };

  return (
    <>
      <ToastContainer />

      <div className="page">
        <Header />

        <div className="page-content d-flex align-items-stretch">
          {/* Side Navbar */}
          <Sidebar />

          <div className="content-inner">
            {/* Page Header*/}
            <header className="page-header">
              <div className="container-fluid">
                <h2 className="no-margin-bottom">Services Dashboard</h2>
              </div>
            </header>
            {/* Updates Section                                                */}
            <section className="updates no-padding-top">
              <div className="container-fluid">
                <div className="row">
                  {/* Daily Feeds */}
                  <div className="col-lg-12 pt-5">
                    <div className="daily-feeds card">
                      <div className="card-close">
                        <div className="dropdown">
                          <button
                            type="button"
                            id="closeCard7"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="dropdown-toggle">
                            <i className="fa fa-ellipsis-v" />
                          </button>
                          <div
                            aria-labelledby="closeCard7"
                            className="dropdown-menu dropdown-menu-right has-shadow"></div>
                        </div>
                      </div>
                      <div className="card-header">
                        <h3 className="h4">Service Post</h3>
                      </div>
                      <div className="card-body no-padding">
                        <form
                          className="container py-5"
                          onSubmit={submitHandler}>
                          <label htmlFor="basic-url" className="form-label">
                            Name of Service
                            <span
                              className="px-1"
                              style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </label>
                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon2">
                              Name of Service
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="..."
                              required
                              onChange={(e) => setServiceName(e.target.value)}
                            />
                          </div>

                          <label htmlFor="basic-url" className="form-label">
                            Reference #
                            <span
                              className="px-2"
                              style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </label>
                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon1">
                              Reference #
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="..."
                              required
                              onChange={(e) => setRefNumber(e.target.value)}
                            />
                          </div>

                          <label htmlFor="basic-url" className="form-label">
                            when ad starts
                            <span
                              className="px-1"
                              style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </label>
                          <div className="input-group mb-3">
                            <input
                              type="date"
                              className="form-control"
                              required
                              onChange={(e) => setStart(e.target.value)}
                            />
                          </div>

                          <label htmlFor="basic-url" className="form-label">
                            # of days for running ad
                            <span
                              className="px-1"
                              style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </label>
                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              <i
                                className="fa fa-hashtag fa-2x"
                                aria-hidden="true"></i>
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              required
                              onChange={(e) => setDays(e.target.value)}
                            />
                          </div>

                          <label htmlFor="basic-url" className="form-label">
                            Description of Service
                            <span
                              className="px-1"
                              style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              Description of Service
                            </span>
                            <textarea
                              className="form-control"
                              aria-label="With textarea"
                              defaultValue={""}
                              required
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </div>
                          <br />
                          <hr />

                          {catList && (
                            <div className="form-group row">
                              <label className="col-sm-3 form-control-label">
                                Category of Service
                                <span
                                  className="px-1"
                                  style={{ color: "red", fontSize: "18px" }}>
                                  *
                                </span>
                              </label>
                              <div className="col-sm-9">
                                <select
                                  name="account"
                                  className="form-control mb-3"
                                  onChange={(e) =>
                                    setCategoryId(e.target.value)
                                  }>
                                  <option>---</option>
                                  {catList.map((cat, key) => {
                                    return (
                                      <option
                                        value={cat.service_category_id}
                                        key={key}>
                                        {cat.service_category_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          )}
                          <hr />
                          {category_id && (
                            <>
                              {subcat && (
                                <div className="form-group row">
                                  <label className="col-sm-3 form-control-label">
                                    Sub-Category of Service
                                    <span
                                      className="px-1"
                                      style={{
                                        color: "red",
                                        fontSize: "18px",
                                      }}>
                                      *
                                    </span>
                                  </label>
                                  <div className="col-sm-9">
                                    <select
                                      name="account"
                                      className="form-control mb-3"
                                      onClick={getSubCategory}
                                      onChange={(e) =>
                                        setSubCategoryId(e.target.value)
                                      }>
                                      <option>---</option>
                                      {subcat.map((cats, key) => {
                                        return (
                                          <option
                                            value={cats.service_sub_category_id}
                                            key={key}>
                                            {cats.sub_category_name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                              )}
                            </>
                          )}

                          <hr />

                          {regionList && (
                            <div className="form-group row pt-2">
                              <label className="col-sm-3 form-control-label">
                                Region of Location
                                <span
                                  className="px-1"
                                  style={{ color: "red", fontSize: "18px" }}>
                                  *
                                </span>
                              </label>
                              <div className="col-sm-9">
                                <select
                                  name="region"
                                  className="form-control mb-3"
                                  required
                                  onChange={(e) => setRegion(e.target.value)}>
                                  <option>---</option>
                                  {regionList.map((content, key) => {
                                    return (
                                      <option
                                        value={content.region_id}
                                        key={key}>
                                        {content.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          )}
                          <hr />

                          {region && (
                            <div className="input-group ">
                              <label
                                htmlFor="fileInput"
                                className="col-sm-3 form-control-label">
                                Images of Service
                                <span
                                  className="px-1"
                                  style={{ color: "red", fontSize: "18px" }}>
                                  *
                                </span>
                              </label>
                              <div className="col-sm-9">
                                <input
                                  id="fileInput"
                                  required
                                  type="file"
                                  className="form-control-file"
                                  accept="image/*"
                                  onChange={onFileChange}
                                />
                              </div>
                            </div>
                          )}
                          <hr />

                          {region && (
                            <div className="text-center pt-4">
                              <button
                                type="submit"
                                name="submit"
                                value="upload"
                                className="btn btn-primary">
                                send post
                              </button>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Page Footer*/}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesPost;

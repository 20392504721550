import axios from "axios";
import Prod from "./Product.module.css";
import { Checkbox } from "primereact/checkbox";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";
import Sidebar from "../../../Components/Sidebar/Sidebar";

const ProductPost = () => {
  const [token, setToken] = useState(null);
  const [product_type, setProduct] = useState("");
  const [ref, setRef] = useState("");
  const [product_name, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [coupon_number, setCoupon] = useState("");
  const [coupon_percentage, setCouponPercentage] = useState("");
  const [days, setDays] = useState("");
  const [starting_date, setStart] = useState("");
  const [discount, setDiscountPercentage] = useState("");
  const [description, setDescription] = useState("");
  const [stock_number, setNumber] = useState("");
  const [delivery, setDelivery] = useState("");
  const [returnable, setReturnable] = useState(false);
  const [return_duration, setReturnDuration] = useState("");
  const [disclaimer, setDisclaimer] = useState("");
  const [cat, setCat] = useState([]);
  const [subcat, setSubCat] = useState([]);
  const [file, setFile] = useState("");
  const [region, setRegion] = useState("");
  const [regionList, setRegionList] = useState(null);
  const [product_category_id, setProductCategoryId] = useState(null);
  const [product_sub_category_id, setProductSubCategoryId] = useState(null);

  useEffect(() => {
    setToken(sessionStorage.getItem("token"));
    axios
      .get(`${process.env.REACT_APP_Base_url}/setup/list_product_category/`)
      .then((res) => {
        // console.log(res.data);
        setCat(res.data.product_category);
      })
      .catch((err) => {
        console.error(err);
      });

    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_url}/setup/get_regions/`, {
          headers: {
            "X-COMPANY-USER-AUTH": token,
          },
        })
        .then((response) => {
          // console.log(response.data);
          setRegionList(response.data.regions);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  const getSubCategory = () => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/setup/list_product_sub_category/`,
        {
          product_cat_uuid: product_category_id,
        }
      )
      .then((resp) => {
        // console.log(resp);
        setSubCat(resp.data.product_sub_category);
      })
      .catch((erro) => {
        console.error(erro);
      });
  };

  const onFileChange = (e) => {
    console.log(e.target.files);
    // select only 4 files
    // if (e.target && e.target.files.length > 4) {
    //   return toast.error("You can only select 4 images");
    // }
    // setFile(e.target.files);
    if (e.target && e.target.files[0].size < 5000000) {
      setFile(e.target.files[0]);
    }
  };

  // const onFileChange = (e) => {
  // console.log(e.target.files[0].size / 1024 / 1024);
  // check if image size is less than 5MB
  //   console.log(e.target.files);
  //   if (e.target && e.target.files) {
  //     only select three images
  //     if (e.target.files.length > 3) {
  //       toast.error("You can select only three images");
  //     } else {
  //       setFile(e.target.files);
  //  else {
  //   toast.error("Image size should be less than 5MB");
  //   return;
  // }
  //     }
  //   }
  // };

  const [change_mind, setChangeMind] = useState("");
  const onChangeMind = (e) => {
    let selectedCities = [...change_mind];
    if (e.checked) selectedCities.push(e.value);
    else selectedCities.splice(selectedCities.indexOf(e.value), 1);
    setChangeMind(selectedCities);
  };

  const [wrong_delivery, setWrongDelivery] = useState("");
  const onWrongDelivery = (e) => {
    let selectedWrongDelivery = [...wrong_delivery];
    if (e.checked) selectedWrongDelivery.push(e.value);
    else
      selectedWrongDelivery.splice(selectedWrongDelivery.indexOf(e.value), 1);
    setWrongDelivery(selectedWrongDelivery);
  };

  const [parts_missing, setPartsMissing] = useState("");
  const onPartsMissing = (e) => {
    let selectedPartsMissing = [...parts_missing];
    if (e.checked) selectedPartsMissing.push(e.value);
    else selectedPartsMissing.splice(selectedPartsMissing.indexOf(e.value), 1);
    setPartsMissing(selectedPartsMissing);
  };

  const [name_mis_match, setNameMismatch] = useState("");
  const onNameMismatch = (e) => {
    let selectedNameMismatch = [...name_mis_match];
    if (e.checked) selectedNameMismatch.push(e.value);
    else selectedNameMismatch.splice(selectedNameMismatch.indexOf(e.value), 1);
    setNameMismatch(selectedNameMismatch);
  };

  const [type_mis_match, setTypeMismatch] = useState("");
  const onTypeMismatch = (e) => {
    let selectedTypeMismatch = [...type_mis_match];
    if (e.checked) selectedTypeMismatch.push(e.value);
    else selectedTypeMismatch.splice(selectedTypeMismatch.indexOf(e.value), 1);
    setTypeMismatch(selectedTypeMismatch);
  };

  const [broken_or_damaged, setBrokenOrDamaged] = useState([]);
  const onBrokenOrDamaged = (e) => {
    let selectedBrokenOrDamaged = [...broken_or_damaged];
    if (e.checked) selectedBrokenOrDamaged.push(e.value);
    else
      selectedBrokenOrDamaged.splice(
        selectedBrokenOrDamaged.indexOf(e.value),
        1
      );
    setBrokenOrDamaged(selectedBrokenOrDamaged);
  };

  const [product_sizes, setProductSizes] = useState([]);
  const onProductSizes = (e) => {
    let selectedProductSizes = [...product_sizes];
    if (e.checked) selectedProductSizes.push(e.value);
    else selectedProductSizes.splice(selectedProductSizes.indexOf(e.value), 1);
    setProductSizes(selectedProductSizes);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("product_category_id", product_category_id);
    formData.append("product_sub_category_id", product_sub_category_id);
    formData.append("region_id", region);
    formData.append("product_type", product_type);
    formData.append("reference", ref);
    formData.append("product_name", product_name);
    formData.append("product_sizes", product_sizes);
    formData.append("price", price);
    formData.append("coupon_number", coupon_number);
    formData.append("coupon_percentage", coupon_percentage);
    formData.append("days", days);
    formData.append("starting_date", starting_date);
    formData.append("discount", discount);
    formData.append("description", description);
    formData.append("stock_number", stock_number);
    formData.append("delivery", delivery);
    formData.append("returnable", returnable);
    formData.append("return_duration", return_duration);
    formData.append("disclaimer", disclaimer);
    formData.append("change_mind", change_mind);
    formData.append("wrong_delivery", wrong_delivery);
    formData.append("parts_missing", parts_missing);
    formData.append("name_mis_match", name_mis_match);
    formData.append("type_mis_match", type_mis_match);
    formData.append("broken_or_damaged", broken_or_damaged);
    formData.append("attached_file", file);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    if (product_type !== "" && product_category_id !== null) {
      await axios
        .post(
          `${process.env.REACT_APP_Base_url}/product/post_product/`,
          formData,
          {
            headers: {
              "X-COMPANY-USER-AUTH": token,
            },
          }
        )
        .then((response) => {
          // console.log(response);

          if (response.data.status === 201) {
            toast.success(response.data.message);

            setTimeout(() => {
              window.location.replace("/drafted_product");
            }, 3000);
          } else {
            toast.success(response.data.message);
            setTimeout(() => {
              window.location.replace("/drafted_product");
            }, 3000);
          }
        })
        .catch((err) => {
          toast.error(err);
          console.log(err);
        });
    } else {
      toast.warn("product type or product category can't be empty");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="page">
        <Header />

        <div className="page-content d-flex align-items-stretch">
          {/* Side Navbar */}
          <Sidebar />
          {/*End of Side Navbar */}

          {/* Main Content */}
          <div className="content-inner">
            <header className="page-header">
              <div className="container-fluid">
                <h2 className="no-margin-bottom">Product Post Dashboard</h2>
              </div>
            </header>
            <section className="updates no-padding-top">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 pt-5">
                    <div className="daily-feeds card">
                      <div className="card-close">
                        <div className="dropdown">
                          <button
                            type="button"
                            id="closeCard7"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="dropdown-toggle">
                            <i className="fa fa-ellipsis-v" />
                          </button>
                          <div
                            aria-labelledby="closeCard7"
                            className="dropdown-menu dropdown-menu-right has-shadow"></div>
                        </div>
                      </div>
                      <div className="card-header">
                        <h3 className="h4">Product Post</h3>
                      </div>
                      <div className="card-body no-padding">
                        <form
                          className="container py-5"
                          onSubmit={submitHandler}>
                          <label htmlFor="basic-url" className="form-label">
                            Name of Product
                            <span
                              className="px-1"
                              style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </label>
                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              <i
                                className="fa fa-product-hunt fa-2x"
                                aria-hidden="true"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              required="required"
                              onChange={(e) => setProductName(e.target.value)}
                            />
                          </div>
                          <label htmlFor="basic-url" className="form-label">
                            Reference #
                            <span
                              className="px-2"
                              style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </label>
                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              <i
                                className="fa fa-hashtag fa-2x"
                                aria-hidden="true"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              required
                              onChange={(e) => setRef(e.target.value)}
                            />
                          </div>
                          <label htmlFor="basic-url" className="form-label">
                            Price of Product
                            <span
                              className="px-1"
                              style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </label>
                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              <b>GH₵</b>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              required
                              onChange={(e) => setPrice(e.target.value)}
                            />
                          </div>
                          <label
                            htmlFor="basic-url"
                            className="form-label d-none">
                            Coupon Number
                          </label>
                          <div className="input-group mb-3 d-none">
                            <span className="input-group-text">
                              <i
                                className="fa fa-ticket fa-2x"
                                aria-hidden="true"></i>
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              onChange={(e) => setCoupon(e.target.value)}
                            />
                          </div>
                          <label
                            htmlFor="basic-url"
                            className="form-label d-none">
                            Coupon %
                          </label>
                          <div className="input-group mb-3 d-none">
                            <span className="input-group-text">
                              <i
                                className="fa fa-percent fa-2x"
                                aria-hidden="true"></i>
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              onChange={(e) =>
                                setCouponPercentage(e.target.value)
                              }
                            />
                          </div>
                          <label htmlFor="basic-url" className="form-label">
                            # of days for running ad
                            <span
                              className="px-1"
                              style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </label>
                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              <i
                                className="fa fa-hashtag fa-2x"
                                aria-hidden="true"></i>
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              required
                              onChange={(e) => setDays(e.target.value)}
                            />
                          </div>
                          <label htmlFor="basic-url" className="form-label">
                            when ad starts
                            <span
                              className="px-1"
                              style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </label>
                          <div className="input-group mb-3">
                            <input
                              type="date"
                              className="form-control"
                              required
                              onChange={(e) => setStart(e.target.value)}
                            />
                          </div>
                          <label htmlFor="basic-url" className="form-label">
                            % of discount
                          </label>
                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              <i
                                className="fa fa-percent fa-2x"
                                aria-hidden="true"></i>
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              onChange={(e) =>
                                setDiscountPercentage(e.target.value)
                              }
                            />
                          </div>
                          <hr />
                          <label htmlFor="basic-url" className="form-label">
                            Return Duration (How many days does it take for an
                            item to be returned)
                          </label>
                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              <i
                                className="fa fa-calendar fa-2x"
                                aria-hidden="true"></i>
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              onChange={(e) =>
                                setReturnDuration(e.target.value)
                              }
                            />
                          </div>
                          <label htmlFor="basic-url" className="form-label">
                            Disclaimer
                          </label>
                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              <i
                                className="fa fa-exclamation-circle fa-2x"
                                aria-hidden="true"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => setDisclaimer(e.target.value)}
                            />
                          </div>
                          <label htmlFor="basic-url" className="form-label">
                            Items in Stock
                            <span
                              className="px-1"
                              style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </label>
                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              <i
                                className="fa fa-hashtag fa-2x"
                                aria-hidden="true"></i>
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              required
                              onChange={(e) => setNumber(e.target.value)}
                            />
                          </div>
                          <label htmlFor="basic-url" className="form-label">
                            Description of Product
                            <span
                              className="px-1"
                              style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <i
                                className="fa fa-info-circle fa-2x"
                                aria-hidden="true"></i>
                            </span>
                            <textarea
                              className="form-control"
                              aria-label="With textarea"
                              required
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </div>{" "}
                          <hr />
                          <div className="form-group row pt-2">
                            <label className="col-sm-3 form-control-label">
                              Product type
                              <span
                                className="px-1"
                                style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </label>
                            <div className="col-sm-9">
                              <select
                                name="product_type"
                                value={product_type}
                                className="form-control mb-3"
                                required="required"
                                onChange={(e) => setProduct(e.target.value)}>
                                <option>---</option>
                                <option>Used Product</option>
                                <option>Brand New Product</option>
                              </select>
                            </div>
                          </div>
                          <hr />
                          {cat && (
                            <div className="form-group row pt-2">
                              <label className="col-sm-3 form-control-label">
                                Product Category
                                <span
                                  className="px-1"
                                  style={{ color: "red", fontSize: "18px" }}>
                                  *
                                </span>
                              </label>
                              <div className="col-sm-9">
                                <select
                                  name="returnable"
                                  className="form-control mb-3"
                                  required
                                  onChange={(e) =>
                                    setProductCategoryId(e.target.value)
                                  }>
                                  <option>---</option>
                                  {cat.map((item, key) => {
                                    return (
                                      <option
                                        value={item.product_category_id}
                                        key={key}>
                                        {item.product_category_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          )}{" "}
                          <hr />
                          {/* product_category_id */}
                          {product_category_id && (
                            <>
                              {subcat && (
                                <div className="form-group row pt-2">
                                  <label className="col-sm-3 form-control-label">
                                    Product Sub-Category
                                    <span
                                      className="px-1"
                                      style={{
                                        color: "red",
                                        fontSize: "18px",
                                      }}>
                                      *
                                    </span>
                                  </label>
                                  <div className="col-sm-9">
                                    <select
                                      name="returnable"
                                      className="form-control mb-3"
                                      required
                                      onClick={getSubCategory}
                                      onChange={(e) =>
                                        setProductSubCategoryId(e.target.value)
                                      }>
                                      <option>---</option>
                                      {subcat.map((items, key) => {
                                        return (
                                          <option
                                            value={
                                              items.product_sub_category_id
                                            }
                                            key={key}>
                                            {items.sub_category_name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                          <hr />
                          <div className="form-group row pt-2">
                            <label className="col-sm-3 form-control-label">
                              {" "}
                              Delivery Available
                              <span
                                className="px-1"
                                style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </label>
                            <div className="col-sm-9">
                              <select
                                name="delivery"
                                value={delivery}
                                className="form-control mb-3"
                                onChange={(e) => setDelivery(e.target.value)}>
                                <option>---</option>
                                <option>Yes</option>
                                <option>No</option>
                              </select>
                            </div>
                          </div>
                          <hr />
                          {regionList && (
                            <div className="form-group row pt-2">
                              <label className="col-sm-3 form-control-label">
                                Region of Location
                                <span
                                  className="px-1"
                                  style={{ color: "red", fontSize: "18px" }}>
                                  *
                                </span>
                              </label>
                              <div className="col-sm-9">
                                <select
                                  name="region"
                                  required
                                  className="form-control mb-3"
                                  onChange={(e) => setRegion(e.target.value)}>
                                  <option>---</option>
                                  {regionList.map((content, key) => {
                                    return (
                                      <option
                                        value={content.region_id}
                                        key={key}>
                                        {content.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          )}
                          <hr />
                          <div id={Prod.size}>
                            <label className="col-sm-6 form-control-label">
                              Product Size{" "}
                              <span className="px-2 text-danger">
                                Only select that which applies
                              </span>
                            </label>
                            <div className="d-flex py-2">
                              <div className="form-check pl-5">
                                <Checkbox
                                  inputId="sm1"
                                  value="SM"
                                  onChange={onProductSizes}
                                  checked={product_sizes.includes(
                                    "SM"
                                  )}></Checkbox>
                                <label
                                  className="form-check-label"
                                  htmlFor="sm1">
                                  SM
                                </label>
                              </div>
                              <div className="form-check pl-5">
                                <Checkbox
                                  inputId="mm1"
                                  value="M"
                                  onChange={onProductSizes}
                                  checked={product_sizes.includes(
                                    "M"
                                  )}></Checkbox>
                                <label
                                  className="form-check-label"
                                  htmlFor="mm1">
                                  M
                                </label>
                              </div>
                              <div className="form-check pl-5">
                                <Checkbox
                                  inputId="LL1"
                                  value="L"
                                  onChange={onProductSizes}
                                  checked={product_sizes.includes(
                                    "L"
                                  )}></Checkbox>
                                <label
                                  className="form-check-label"
                                  htmlFor="LL1">
                                  L
                                </label>
                              </div>

                              <div className="form-check pl-5">
                                <Checkbox
                                  inputId="XL1"
                                  value="XL"
                                  onChange={onProductSizes}
                                  checked={product_sizes.includes(
                                    "XL"
                                  )}></Checkbox>
                                <label
                                  className="form-check-label"
                                  htmlFor="XL1">
                                  XL
                                </label>
                              </div>

                              <div className="form-check pl-5">
                                <Checkbox
                                  inputId="XXL1"
                                  value="XXL"
                                  onChange={onProductSizes}
                                  checked={product_sizes.includes(
                                    "XXL"
                                  )}></Checkbox>
                                <label
                                  className="form-check-label"
                                  htmlFor="XX1">
                                  XXL
                                </label>
                              </div>
                            </div>

                            <div className="d-flex py-2">
                              <div className="form-check pl-5">
                                <Checkbox
                                  inputId="S37"
                                  value="37"
                                  onChange={onProductSizes}
                                  checked={product_sizes.includes(
                                    "37"
                                  )}></Checkbox>
                                <label
                                  className="form-check-label"
                                  htmlFor="S37">
                                  37
                                </label>
                              </div>
                              <div className="form-check pl-5">
                                <Checkbox
                                  inputId="S38"
                                  value="38"
                                  onChange={onProductSizes}
                                  checked={product_sizes.includes(
                                    "38"
                                  )}></Checkbox>
                                <label
                                  className="form-check-label"
                                  htmlFor="S38">
                                  38
                                </label>
                              </div>
                              <div className="form-check pl-5">
                                <Checkbox
                                  inputId="S39"
                                  value="39"
                                  onChange={onProductSizes}
                                  checked={product_sizes.includes(
                                    "39"
                                  )}></Checkbox>
                                <label
                                  className="form-check-label"
                                  htmlFor="S39">
                                  39
                                </label>
                              </div>

                              <div className="form-check pl-5">
                                <Checkbox
                                  inputId="S40"
                                  value="40"
                                  onChange={onProductSizes}
                                  checked={product_sizes.includes(
                                    "40"
                                  )}></Checkbox>
                                <label
                                  className="form-check-label"
                                  htmlFor="S40">
                                  40
                                </label>
                              </div>

                              <div className="form-check pl-5">
                                <Checkbox
                                  inputId="S41"
                                  value="41"
                                  onChange={onProductSizes}
                                  checked={product_sizes.includes(
                                    "41"
                                  )}></Checkbox>
                                <label
                                  className="form-check-label"
                                  htmlFor="S41">
                                  41
                                </label>
                              </div>
                              <div className="form-check pl-5">
                                <Checkbox
                                  inputId="S42"
                                  value="42"
                                  onChange={onProductSizes}
                                  checked={product_sizes.includes(
                                    "42"
                                  )}></Checkbox>
                                <label
                                  className="form-check-label"
                                  htmlFor="S42">
                                  42
                                </label>
                              </div>
                              <div className="form-check pl-5">
                                <Checkbox
                                  inputId="S43"
                                  value="43"
                                  onChange={onProductSizes}
                                  checked={product_sizes.includes(
                                    "43"
                                  )}></Checkbox>
                                <label
                                  className="form-check-label"
                                  htmlFor="S43">
                                  43
                                </label>
                              </div>
                              <div className="form-check pl-5">
                                <Checkbox
                                  inputId="S44"
                                  value="44"
                                  onChange={onProductSizes}
                                  checked={product_sizes.includes(
                                    "44"
                                  )}></Checkbox>
                                <label
                                  className="form-check-label"
                                  htmlFor="S44">
                                  44
                                </label>
                              </div>
                              <div className="form-check pl-5">
                                <Checkbox
                                  inputId="S45"
                                  value="45"
                                  onChange={onProductSizes}
                                  checked={product_sizes.includes(
                                    "45"
                                  )}></Checkbox>
                                <label
                                  className="form-check-label"
                                  htmlFor="S45">
                                  45
                                </label>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <br />
                          <div>
                            <label className="col-sm-3 form-control-label">
                              Is product Returnable
                            </label>
                            <div className="form-check pl-5">
                              <input
                                className="form-check-input"
                                value="yes"
                                type="checkbox"
                                onChange={(e) => setReturnable(!returnable)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault">
                                Yes
                              </label>
                            </div>
                            <div className="form-check pl-5">
                              <input
                                className="form-check-input"
                                value="no"
                                type="checkbox"
                                onChange={(e) => setReturnable(returnable)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked">
                                No
                              </label>
                            </div>
                            <hr />
                          </div>
                          <br />
                          {/* RETURNABLES */}
                          {returnable && (
                            <div className="container">
                              <div className="container py-3">
                                <h6>
                                  Conditions needed for returnables (leave blank
                                  if no condition is allowed)
                                </h6>{" "}
                                <hr />
                              </div>

                              <div className="container">
                                <h6>
                                  <span className="px-3">1 :</span>Change of
                                  Mind
                                </h6>
                                <div className="form-check form-check-inline ">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb1"
                                      value="Must be returned in"
                                      onChange={onChangeMind}
                                      checked={change_mind.includes(
                                        "Must be returned in"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb1"
                                      className="form-check-label">
                                      Must be returned in
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb2"
                                      value="No physical defects"
                                      onChange={onChangeMind}
                                      checked={change_mind.includes(
                                        "No physical defects"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb2"
                                      className="form-check-label">
                                      No physical defects
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb3"
                                      value="Sealed"
                                      onChange={onChangeMind}
                                      checked={change_mind.includes(
                                        "Sealed"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb3"
                                      className="form-check-label">
                                      Sealed
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb4"
                                      value="Unused"
                                      onChange={onChangeMind}
                                      checked={change_mind.includes(
                                        "Unused"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb4"
                                      className="form-check-label">
                                      Unused
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb4"
                                      value="Return the Complete package of delivery"
                                      onChange={onChangeMind}
                                      checked={change_mind.includes(
                                        "Return the Complete package of delivery"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb4"
                                      className="form-check-label">
                                      Return the Complete package of delivery
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="container py-2">
                                <h6>
                                  <span className="px-3">2 :</span>Wrong
                                  Delivery
                                </h6>
                                <div className="form-check form-check-inline ">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb5"
                                      value="Must be returned in"
                                      onChange={onWrongDelivery}
                                      checked={wrong_delivery.includes(
                                        "Must be returned in"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb5"
                                      className="form-check-label">
                                      Must be returned in
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb6"
                                      value="No physical defects"
                                      onChange={onWrongDelivery}
                                      checked={wrong_delivery.includes(
                                        "No physical defects"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb6"
                                      className="form-check-label">
                                      No physical defects
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb7"
                                      value="Sealed"
                                      onChange={onWrongDelivery}
                                      checked={wrong_delivery.includes(
                                        "Sealed"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb7"
                                      className="form-check-label">
                                      Sealed
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb8"
                                      value="Unused"
                                      onChange={onWrongDelivery}
                                      checked={wrong_delivery.includes(
                                        "Unused"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb8"
                                      className="form-check-label">
                                      Unused
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb9"
                                      value="Return the Complete package of delivery"
                                      onChange={onWrongDelivery}
                                      checked={wrong_delivery.includes(
                                        "Return the Complete package of delivery"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb9"
                                      className="form-check-label">
                                      Return the Complete package of delivery
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="container py-2">
                                <h6>
                                  <span className="px-3">3 :</span>Parts missing
                                </h6>
                                <div className="form-check form-check-inline ">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb10"
                                      value="Must be returned in"
                                      onChange={onPartsMissing}
                                      checked={parts_missing.includes(
                                        "Must be returned in"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb10"
                                      className="form-check-label">
                                      Must be returned in
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb11"
                                      value="No physical defects"
                                      onChange={onPartsMissing}
                                      checked={parts_missing.includes(
                                        "No physical defects"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb11"
                                      className="form-check-label">
                                      No physical defects
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb12"
                                      value="Sealed"
                                      onChange={onPartsMissing}
                                      checked={parts_missing.includes(
                                        "Sealed"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb12"
                                      className="form-check-label">
                                      Sealed
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb13"
                                      value="Unused"
                                      onChange={onPartsMissing}
                                      checked={parts_missing.includes(
                                        "Unused"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb13"
                                      className="form-check-label">
                                      Unused
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb14"
                                      value="Return the Complete package of delivery"
                                      onChange={onPartsMissing}
                                      checked={parts_missing.includes(
                                        "Return the Complete package of delivery"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb14"
                                      className="form-check-label">
                                      Return the Complete package of delivery
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="container py-2">
                                <h6>
                                  <span className="px-3">4 :</span>Product name
                                  mis-match
                                </h6>
                                <div className="form-check form-check-inline ">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb15"
                                      value="Must be returned in"
                                      onChange={onNameMismatch}
                                      checked={name_mis_match.includes(
                                        "Must be returned in"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb15"
                                      className="form-check-label">
                                      Must be returned in
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb16"
                                      value="No physical defects"
                                      onChange={onNameMismatch}
                                      checked={name_mis_match.includes(
                                        "No physical defects"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb16"
                                      className="form-check-label">
                                      No physical defects
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb17"
                                      value="Sealed"
                                      onChange={onNameMismatch}
                                      checked={name_mis_match.includes(
                                        "Sealed"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb17"
                                      className="form-check-label">
                                      Sealed
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb18"
                                      value="Unused"
                                      onChange={onNameMismatch}
                                      checked={name_mis_match.includes(
                                        "Unused"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb18"
                                      className="form-check-label">
                                      Unused
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb19"
                                      value="Return the Complete package of delivery"
                                      onChange={onNameMismatch}
                                      checked={name_mis_match.includes(
                                        "Return the Complete package of delivery"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb19"
                                      className="form-check-label">
                                      Return the Complete package of delivery
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="container py-2">
                                <h6>
                                  <span className="px-3">5 :</span>Product type
                                  mis-match
                                </h6>

                                <div className="form-check form-check-inline ">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb20"
                                      value="Must be returned in"
                                      onChange={onTypeMismatch}
                                      checked={type_mis_match.includes(
                                        "Must be returned in"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb20"
                                      className="form-check-label">
                                      Must be returned in
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb21"
                                      value="No physical defects"
                                      onChange={onTypeMismatch}
                                      checked={type_mis_match.includes(
                                        "No physical defects"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb21"
                                      className="form-check-label">
                                      No physical defects
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb22"
                                      value="Sealed"
                                      onChange={onTypeMismatch}
                                      checked={type_mis_match.includes(
                                        "Sealed"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb22"
                                      className="form-check-label">
                                      Sealed
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb23"
                                      value="Unused"
                                      onChange={onTypeMismatch}
                                      checked={type_mis_match.includes(
                                        "Unused"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb23"
                                      className="form-check-label">
                                      Unused
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb24"
                                      value="Return the Complete package of delivery"
                                      onChange={onTypeMismatch}
                                      checked={type_mis_match.includes(
                                        "Return the Complete package of delivery"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb24"
                                      className="form-check-label">
                                      Return the Complete package of delivery
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="container py-2">
                                <h6>
                                  <span className="px-3">6 :</span>Broken or
                                  damage
                                </h6>

                                <div className="form-check form-check-inline ">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb25"
                                      value="Must be returned in"
                                      onChange={onBrokenOrDamaged}
                                      checked={broken_or_damaged.includes(
                                        "Must be returned in"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb25"
                                      className="form-check-label">
                                      Must be returned in
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb26"
                                      value="No physical defects"
                                      onChange={onBrokenOrDamaged}
                                      checked={broken_or_damaged.includes(
                                        "No physical defects"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb26"
                                      className="form-check-label">
                                      No physical defects
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb27"
                                      value="Sealed"
                                      onChange={onBrokenOrDamaged}
                                      checked={broken_or_damaged.includes(
                                        "Sealed"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb27"
                                      className="form-check-label">
                                      Sealed
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb28"
                                      value="Unused"
                                      onChange={onBrokenOrDamaged}
                                      checked={broken_or_damaged.includes(
                                        "Unused"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb28"
                                      className="form-check-label">
                                      Unused
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check form-check-inline">
                                  <div className="p-col-12">
                                    <Checkbox
                                      inputId="cb29"
                                      value="Return the Complete package of delivery"
                                      onChange={onBrokenOrDamaged}
                                      checked={broken_or_damaged.includes(
                                        "Return the Complete package of delivery"
                                      )}></Checkbox>
                                    <label
                                      htmlFor="cb29"
                                      className="form-check-label">
                                      Return the Complete package of delivery
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          )}
                          {/*End of RETURNABLES */}
                          {region && (
                            <div className="input-group ">
                              <label
                                htmlFor="fileInput"
                                className="col-sm-3 form-control-label">
                                Image of Product
                                <span
                                  className="px-1"
                                  style={{ color: "red", fontSize: "18px" }}>
                                  *
                                </span>
                              </label>
                              <div className="col-sm-9">
                                <input
                                  required
                                  type="file"
                                  multiple
                                  className="form-control-file"
                                  accept="image/*"
                                  alt="Image"
                                  onChange={onFileChange}
                                />
                              </div>
                            </div>
                          )}
                          <hr />
                          {region && delivery && (
                            <div className="text-center pt-4">
                              <button type="submit" className="btn btn-primary">
                                send post
                              </button>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Page Footer*/}
            <Footer />
          </div>
          {/*End of Main Content */}
        </div>
      </div>
    </>
  );
};

export default ProductPost;

import axios from "axios";
import { useState, useEffect } from "react";
const baseUrl = process.env.REACT_APP_Base_url;

export const useFetch = (url) => {
  const [response, setResponse] = useState("");

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    if (tk) {
      axios
        .get(`${baseUrl}${url}`, {
          headers: {
            "X-COMPANY-USER-AUTH": tk,
          },
        })
        .then((res) => {
          // console.log(res.data);
          setResponse(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [url]);

  return [response];
};

export const usePost = (url, payload) => {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");

    if (payload) {
      axios
        .post(
          `${baseUrl}${url}`,
          {
            company_id: payload,
          },
          {
            headers: {
              "X-COMPANY-USER-AUTH": tk,
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          setResponse(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [payload, url]);

  return [response];
};

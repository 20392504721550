import { createSlice } from "@reduxjs/toolkit";

export const companiesSlice = createSlice({
  name: "USER",
  initialState: {
    companies_details: {},
    cart_items: [],
    trend_list: [],
    hot_list: [],
    companies_list: [],
    subproducts_list: [],
    users_change: [],
  },
  reducers: {
    setCompanyInfo: (state, action) => {
      state.companies_details = action.payload;
    },
    updateCartList: (state, action) => {
      state.cart_items = action.payload;
    },
    updateTrendingList: (state, action) => {
      state.trend_list = action.payload;
    },
    updateHotList: (state, action) => {
      state.hot_list = action.payload;
    },
    updateCompaniesList: (state, action) => {
      state.companies_list = action.payload;
    },
    updateSubProductsList: (state, action) => {
      state.subproducts_list = action.payload;
    },
    updateUsersChange: (state, action) => {
      state.users_change = action.payload;
    },
  },
});

export const {
  setCompanyInfo,
  updateCartList,
  updateTrendingList,
  updateHotList,
  updateCompaniesList,
  updateSubProductsList,
  updateUsersChange,
} = companiesSlice.actions;
export default companiesSlice.reducer;

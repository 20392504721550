import React, { useEffect, useState } from "react";
import axios from "axios";
import { TabView, TabPanel } from "primereact/tabview";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import unreadStyles from "./Notifications.module.css";
import Sidebar from "../../Components/Sidebar/Sidebar";

const Notification = () => {
  const [companyMessages, setCompanyMessages] = useState([]);
  const [pendingDelivery, setPendingDelivery] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [id, setId] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    // get token details
    const tk = sessionStorage.getItem("token");
    setToken(tk);
  }, []);

  useEffect(() => {
    // GET COMPANY DETAILS

    if (token) {
      axios
        .get(
          `${process.env.REACT_APP_Base_url}/company/view_company_details/`,
          {
            headers: {
              "X-COMPANY-USER-AUTH": token,
            },
          }
        )
        .then((resp) => {
          console.log(resp.data);
          setId(resp.data.company_details.company_uuid);
        });

      // GET COMPANY REVIEWS
      axios
        .get(`${process.env.REACT_APP_Base_url}/company/get_company_reviews/`, {
          headers: {
            "X-COMPANY-USER-AUTH": token,
          },
        })
        .then((res) => {
          console.log(res.data);
          setReviews(res.data.review_rating);
        })
        .catch((err) => {
          console.log(err);
        });

      // GET PENDING DELIVERY NOTIFICATIONS
      if (id) {
        axios
          .post(
            `${process.env.REACT_APP_Base_url}/payment/company_messages/`,
            // `https://rosmallonline.com:8080/ecoms-api/payment/company_messages/`,
            {
              company_id: id,
            },
            {
              headers: {
                "X-COMPANY-USER-AUTH": token,
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            setPendingDelivery(response.data.company_messages);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      // GET MESSAGES FOR COMPANY

      if (id) {
        axios
          .post(
            `${process.env.REACT_APP_Base_url}/contact-company/list_company_enquiries/`,
            {
              company_id: id,
            },
            {
              headers: {
                "X-COMPANY-USER-AUTH": token,
              },
            }
          )
          .then((respon) => {
            console.log(respon.data);
            setCompanyMessages(respon.data.company_messages);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [id, token]);

  return (
    <div className="page">
      {/* Main Navbar*/}
      <Header />
      <div className="page-content d-flex align-items-stretch">
        {/* Side Navbar */}
        <Sidebar />

        <div className="content-inner">
          {/* Page Header*/}
          <header className="page-header">
            <div className="container-fluid">
              <h2 className="no-margin-bottom">Notifications Dashboard</h2>
            </div>
          </header>

          {/* Updates Section  */}
          <section className="updates no-padding-top">
            <div className="container-fluid">
              <div className="row">
                {/* Daily Feeds */}
                <div className="col-lg-12 pt-5">
                  <div className="daily-feeds card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard7"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard7"
                          className="dropdown-menu dropdown-menu-right has-shadow"></div>
                      </div>
                    </div>
                    <div className="card-header">
                      <h3 className="h4">Notifications</h3>
                    </div>
                    <div className="card-body no-padding">
                      <TabView
                        activeIndex={activeIndex}
                        onTabChange={(e) => setActiveIndex(e.index)}>
                        {/* PENDING DELIVERY TAB */}
                        <TabPanel header="PENDING ORDER">
                          {pendingDelivery && pendingDelivery.length > 0 ? (
                            <div className="card-body no-padding">
                              {pendingDelivery.map((pending) => (
                                <>
                                  {pending?.status === "Unread" ? (
                                    <div
                                      className="item"
                                      key={pending?.message_id}
                                      id={unreadStyles.unread}>
                                      <div className="feed d-flex justify-content-between">
                                        <div className="feed-body d-flex justify-content-between">
                                          <a href="#/" className="feed-profile">
                                            <img
                                              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                              alt="person"
                                              className="img-fluid rounded-circle"
                                            />
                                          </a>
                                          <div
                                            className="content"
                                            id={unreadStyles.content}>
                                            <h5>{pending?.title}</h5>
                                            <span></span>
                                            <div className="full-date">
                                              <small
                                                style={{
                                                  color: "#000",
                                                  fontWeight: "900",
                                                }}>
                                                {pending?.date_created}
                                              </small>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="CTAs">
                                          <Link
                                            to="/payout_details"
                                            state={{ pending }}
                                            className="btn btn-xs"
                                            id={unreadStyles.btn_unread}>
                                            <i className="fa fa-eye" />
                                            View
                                          </Link>
                                          {/* <Link
                                            to="/confirmation_payment_details"
                                            state={{ id: pending.message_id }}
                                            className="btn btn-xs"
                                            id={unreadStyles.btn_unread}>
                                            <i className="fa fa-eye" />
                                            Unread
                                          </Link> */}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className="item"
                                      key={pending.message_id}>
                                      <div className="feed d-flex justify-content-between">
                                        <div className="feed-body d-flex justify-content-between">
                                          <a href="#/" className="feed-profile">
                                            <img
                                              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                              alt="person"
                                              className="img-fluid rounded-circle"
                                            />
                                          </a>
                                          <div className="content">
                                            <h5>{pending.title}</h5>
                                            <span></span>
                                            <div className="full-date">
                                              <small>
                                                {pending.date_created}
                                              </small>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="CTAs">
                                          <Link
                                            to="/confirmation_payment_details"
                                            state={{ id: pending.message_id }}
                                            className="btn btn-xs btn-secondary">
                                            Read more ...
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ))}
                            </div>
                          ) : (
                            <div className="card-body no-padding">
                              <p className="text-center">No pending delivery</p>
                            </div>
                          )}
                        </TabPanel>

                        {/* REVIEWS TAB */}
                        <TabPanel header="REVIEWS">
                          {reviews && reviews.length > 0 ? (
                            <div className="card-body no-padding">
                              {reviews.map((review) => (
                                <div className="item" key={review.review_id}>
                                  <div className="feed d-flex justify-content-between">
                                    <div className="feed-body d-flex justify-content-between">
                                      <a href="#/" className="feed-profile">
                                        <img
                                          src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                          alt="person"
                                          className="img-fluid rounded-circle"
                                        />
                                      </a>
                                      <div className="content">
                                        <h5>{review.shopper.first_name}</h5>
                                        <span></span>
                                        <div className="full-date">
                                          <small>{review.date_created}</small>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="CTAs">
                                      <Link
                                        to="/notification_details"
                                        state={{ id: review.review_id }}
                                        className="btn btn-xs btn-secondary">
                                        Read more ...
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="card-body no-padding">
                              <p className="text-center">No Reviews</p>
                            </div>
                          )}
                        </TabPanel>

                        {/* MESSAGES TAB */}
                        <TabPanel header="MESSAGES">
                          {companyMessages && companyMessages.length > 0 ? (
                            <div className="card-body no-padding">
                              {companyMessages.map((messages) => (
                                <div className="item" key={messages.contact_id}>
                                  <div className="feed d-flex justify-content-between">
                                    <div className="feed-body d-flex justify-content-between">
                                      <a href="#/" className="feed-profile">
                                        <img
                                          src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                          alt="person"
                                          className="img-fluid rounded-circle"
                                        />
                                      </a>
                                      <div className="content">
                                        <h5>{messages.first_name}</h5>
                                        <span></span>
                                        <div className="full-date">
                                          <small>
                                            {messages.date.slice(0, 10)}
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="CTAs">
                                      <Link
                                        to="/message_details"
                                        state={{ id: messages.contact_id }}
                                        className="btn btn-xs btn-secondary">
                                        Read more ...
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="card-body no-padding">
                              <p className="text-center">No Messages</p>
                            </div>
                          )}
                        </TabPanel>
                      </TabView>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Page Footer*/}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Notification;

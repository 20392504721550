import React from "react";

const Footer = () => {
  let date = new Date().getFullYear();

  return (
    <div>
      <footer className="main-footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <p>RosmallOnline © {date}</p>
            </div>
            <div className="col-sm-6 text-right">
              <p>
                Owned by{" "}
                <a
                  href="https://rosmallonline.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="external">
                  RosmallOnline
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

import React, { useEffect, useRef } from "react";
import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";

const Post = () => {
  const toast = useRef(null);

  const accept = () => {
    // toast.current.show({
    //   severity: "info",
    //   summary: "Confirmed",
    //   detail: "You have accepted",
    //   life: 3000,
    // });
    window.location.href = "/product_post";
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  useEffect(() => {
    const confirm1 = () => {
      confirmDialog({
        message: "click yes if posting items and no when posting cars.",
        header: "Posting Confirmation",
        icon: "pi pi-spin pi-cog",
        accept,
        reject,
      });
    };
    confirm1();
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="page">
        <Header />

        <div className="page-content d-flex align-items-stretch">
          {/* Side Navbar */}
          <Sidebar />
          {/*End of Side Navbar */}

          {/* Main Content */}
          <div className="content-inner">
            <header className="page-header">
              <div className="container-fluid">
                <h2 className="no-margin-bottom">Post Dashboard</h2>
              </div>
            </header>

            {/* Page Footer*/}
            <Footer />
          </div>
          {/*End of Main Content */}
        </div>
      </div>
    </>
  );
};

export default Post;

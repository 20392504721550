import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFetch } from "../../Hooks/CustomHooks";

const Header = () => {
  const [accountType, setAccountType] = useState(null);

  const endSession = () => {
    // Remove all saved data from sessionStorage
    sessionStorage.clear();
  };

  useEffect(() => {
    const tk = sessionStorage.getItem("accountType");
    setAccountType(tk);
  }, []);

  // used a custom hook to call the notifications API
  const [response] = useFetch("/company/total_notifications/");
  // console.log(response);
  const notificationDetails = response.total_notifications;

  return (
    <>
      <ToastContainer />

      <header className="header">
        <nav className="navbar">
          {/* Search Box*/}
          <div className="search-box">
            <button className="dismiss">
              <i className="icon-close" />
            </button>
            <form id="searchForm" action="#/" role="search">
              <input
                type="search"
                placeholder="What are you looking for..."
                className="form-control"
              />
            </form>
          </div>

          <div className="container-fluid">
            <div className="navbar-holder d-flex align-items-center justify-content-between">
              {/* Navbar Header*/}
              <div className="navbar-header">
                {/* Navbar Brand */}
                <a
                  href="https://rosmallonline.com/"
                  className="navbar-brand d-none d-sm-inline-block">
                  <div className="brand-text d-none d-lg-inline-block">
                    <span> </span>
                    <strong>RosmallOnline</strong>
                  </div>
                  <div className="brand-text d-none d-sm-inline-block d-lg-none">
                    <strong>RosmallOnline</strong>
                  </div>
                </a>
                {/* Toggle Button*/}
                <a id="toggle-btn" href="#/" className="menu-btn active">
                  <span />
                  <span />
                  <span />
                </a>
              </div>
              {/* Navbar Menu */}
              <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
                {/* Notifications*/}

                <li className="nav-item dropdown">
                  {response && (
                    <Link
                      to="#/"
                      id="notifications"
                      rel="nofollow"
                      data-target="#"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="nav-link">
                      <i className="fa fa-bell-o" />{" "}
                      <span className="badge bg-red badge-corner">
                        {notificationDetails}
                      </span>
                    </Link>
                  )}
                  <ul aria-labelledby="notifications" className="dropdown-menu">
                    <li>
                      <a rel="nofollow" href="#/" className="dropdown-item">
                        <div className="notification">
                          <div className="notification-content">
                            <i className="fa fa-envelope bg-green" />
                            You have {notificationDetails} message(s){" "}
                          </div>
                        </div>
                      </a>
                    </li>

                    <li>
                      <a
                        rel="nofollow"
                        href="/notifications"
                        className="dropdown-item all-notifications text-center">
                        {" "}
                        <strong>view all notifications</strong>
                      </a>
                    </li>
                  </ul>
                </li>

                {/* Languages dropdown    */}
                <li className="nav-item dropdown">
                  <Link
                    id="languages"
                    rel="nofollow"
                    data-target="#"
                    to="#/"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="nav-link language dropdown-toggle">
                    <span className="d-none d-sm-inline-block">Post Ads</span>
                  </Link>
                  {accountType && accountType === "Pay As You Go" ? (
                    <ul aria-labelledby="languages" className="dropdown-menu">
                      <li>
                        <a href="/post" className="dropdown-item">
                          Post New Product{" "}
                        </a>
                      </li>
                      <li>
                        <a href="/drafted_product" className="dropdown-item">
                          Drafted Product{" "}
                        </a>
                      </li>
                      <li>
                        <a href="/active_products" className="dropdown-item">
                          Active Product
                        </a>
                      </li>
                      <li>
                        <a href="/retired_products" className="dropdown-item">
                          Retired Products{" "}
                        </a>
                      </li>
                    </ul>
                  ) : (
                    <ul aria-labelledby="languages" className="dropdown-menu">
                      <li>
                        <a href="/post" className="dropdown-item">
                          Post New Product{" "}
                        </a>
                      </li>
                      <li>
                        <a href="/services_post" className="dropdown-item">
                          Post New Service
                        </a>
                      </li>
                      <li>
                        <a href="/drafted_product" className="dropdown-item">
                          Drafted Product{" "}
                        </a>
                      </li>
                      <li>
                        <a href="/drafted_services" className="dropdown-item">
                          Drafted Service{" "}
                        </a>
                      </li>
                      <li>
                        <a href="/active_products" className="dropdown-item">
                          Active Product
                        </a>
                      </li>
                      <li>
                        <a href="/active_services" className="dropdown-item">
                          Active Service{" "}
                        </a>
                      </li>
                      <li>
                        <a href="/retired_products" className="dropdown-item">
                          Retired Products{" "}
                        </a>
                      </li>
                      <li>
                        <a href="/retired_services" className="dropdown-item">
                          Retired Services{" "}
                        </a>
                      </li>
                    </ul>
                  )}
                </li>

                {/* Logout    */}
                <li className="nav-item">
                  <a href="/" className="nav-link logout" onClick={endSession}>
                    {" "}
                    <span className="d-none d-sm-inline">Logout</span>
                    <i className="fa fa-sign-out" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;

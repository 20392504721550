import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";

const PaymentServices = () => {
  const location = useLocation();
  console.log(location);
  const [token, setToken] = useState(null);
  const [state, setState] = useState(null);
  const [id, setId] = useState(null);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
    if (location.state) {
      setId(location.state.id);
    }
  }, [token, location.state]);

  useEffect(() => {
    if (id) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/product/service_details/`,
          {
            service_id: id,
          },
          {
            headers: {
              "x-user-pass-auth":
                "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          setState(response.data.service_details);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id, token]);

  const handlePayment = async (e) => {
    e.preventDefault();
    await axios
      .post(
        "https://app.mybusinesspay.com/payment/v1.0/initialize",
        {
          metadata: {
            order_id: 98876,
            product_name: state.service_name,
            product_description: state.description,
          },
          callback: `${process.env.REACT_APP_Callback_url}`,
          post_url: `${process.env.REACT_APP_Base_url}/payment/cart_payment_callback/`,
          pub_key: process.env.REACT_APP_Pub_key,
          first_name: state.company.company_name,
          currency: "GHS",
          amount: state.amount_to_pay,
          phone_number: state.company.phone_number,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_Payment_key,
          },
        }
      )
      .then((response) => {
        console.log(response);
        window.location.replace(response.data.url);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="page">
      {/* Main Navbar*/}
      <Header />

      <div className="page-content d-flex align-items-stretch">
        {/* Side Navbar */}
        <Sidebar />

        <div className="content-inner">
          {/* Forms Section*/}
          <section className="forms">
            <div className="container-fluid">
              <div className="row">
                {/* Form Elements */}
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard5"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard5"
                          className="dropdown-menu dropdown-menu-right has-shadow"></div>
                      </div>
                    </div>
                    <div className="card-header d-flex align-items-center">
                      <h3 className="h4"> Payment Details </h3>
                    </div>
                    {state && (
                      <div className="card-body">
                        <div className="line"> </div>
                        <div className="row">
                          {/* ORDER SUMMARY*/}
                          <div className="col-12">
                            <div className="card border-0 rounded-0 p-lg-4 bg-light">
                              {state && (
                                <div className="card-body">
                                  <h5 className="text-uppercase mb-4">
                                    Your order
                                  </h5>
                                  <ul className="list-unstyled mb-0">
                                    <li className="d-flex align-items-center justify-content-between">
                                      <strong className="small font-weight-bold">
                                        Service name
                                      </strong>
                                      <span className="text-muted small">
                                        {state.service_name}
                                      </span>
                                    </li>
                                    <li className="border-bottom my-2" />
                                    <li className="d-flex align-items-center justify-content-between">
                                      <strong className="small font-weight-bold">
                                        Starting date of Ad
                                      </strong>
                                      <span className="text-muted small">
                                        {state.start_date}
                                      </span>
                                    </li>
                                    <li className="border-bottom my-2" />
                                    <li className="d-flex align-items-center justify-content-between">
                                      <strong className="small font-weight-bold">
                                        Number of days for running Ad
                                      </strong>
                                      <span className="text-muted small">
                                        {state.running_days}
                                      </span>
                                    </li>
                                    <li className="border-bottom my-2" />
                                    <li className="d-flex align-items-center justify-content-between">
                                      <strong className="text-uppercase small font-weight-bold">
                                        Total Cost
                                      </strong>
                                      <span>
                                        GH₵ <span>{state.amount_to_pay}</span>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="line" />
                          <div className="form-group row justify-content-center">
                            <div className="col-12 offset-sm-3">
                              <button
                                type="submit"
                                onClick={handlePayment}
                                className="btn btn-primary">
                                Make Payment
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Page Footer*/}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default PaymentServices;

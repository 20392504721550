import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  const [notifications, setNotifications] = useState([]);
  const [id, setId] = useState(null);
  const [token, setToken] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // get token details
    const tk = sessionStorage.getItem("token");
    setToken(tk);
    axios
      .get(`${process.env.REACT_APP_Base_url}/company/view_company_details/`, {
        headers: {
          "X-COMPANY-USER-AUTH": token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setId(res.data.company_details.company_uuid);
      });

    if (id) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/contact-company/list_company_enquiries/`,
          {
            company_id: id,
          },
          {
            headers: {
              "X-COMPANY-USER-AUTH": tk,
            },
          }
        )
        .then((resp) => {
          // console.log(resp.data);
          setNotifications(resp.data.company_messages);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, token]);

  return (
    // <>
    //   <nav>
    //     <ul>
    //       {notifications && (
    //         <li>
    //           <a href="/Task">
    //             <img
    //               src="https://img.icons8.com/ios/34/000000/task.png"
    //               alt="task"
    //               className="px-3"
    //             />
    //             Task
    //             <span className="badge bg-red badge-corner">
    //               {notifications.length}
    //             </span>
    //           </a>
    //         </li>
    //       )}
    //       {/* <li>
    //         <a href="/PendingDelivery">
    //           {" "}
    //           <img
    //             src="https://img.icons8.com/dotty/34/000000/delivery.png"
    //             alt="pending Delivery"
    //             className="px-2"
    //           />{" "}
    //           Pending Delivery{" "}
    //           <span className="badge bg-red badge-corner">{0}</span>
    //         </a>
    //       </li> */}
    //     </ul>
    //   </nav>
    // </>
    <>
      <nav className="side-navbar">
        {/* Sidebar Navigation Menus*/}
        {/* <div className="sidebar-header d-flex align-items-center">
            <div className="title">
              {state.companyDetails && (
                <h4 className="h4">{state.companyDetails.company_name}</h4>
              )}
            </div>
          </div> */}
        {/* <span className="heading">Main</span> */}
        <ul className="list-unstyled">
          <li className={location?.pathname === "/dashboard" ? "active" : null}>
            <a href="/dashboard">
              {" "}
              <img
                src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/34/000000/external-dashboard-agile-flaticons-lineal-color-flat-icons.png"
                alt="Dashboard"
                className="px-2"
              />{" "}
              Dashboard{" "}
            </a>
          </li>

          <li className={location?.pathname === "/profile" ? "active" : null}>
            <a href="/profile">
              {" "}
              <img
                src="https://img.icons8.com/ios/34/000000/user-male-circle.png"
                alt="Profile"
                className="px-2"
              />{" "}
              Profile{" "}
            </a>
          </li>

          <li
            className={
              location?.pathname === "/notifications" ? "active" : null
            }>
            <a href="/notifications">
              {" "}
              <img
                src="https://img.icons8.com/ios/34/000000/apple-mail.png"
                alt="Notification"
                className="px-2"
              />{" "}
              Notification{" "}
            </a>
          </li>

          <li
            className={
              location?.pathname === "/update_profile" ? "active" : null
            }>
            <a href="/update_profile">
              {" "}
              <img
                src="https://img.icons8.com/ios/34/000000/edit-user-male.png"
                alt="update"
                className="px-2"
              />{" "}
              Update Details{" "}
            </a>
          </li>

          <li
            className={
              location?.pathname === "/account_details" ? "active" : null
            }>
            <a href="/account_details">
              {" "}
              <img
                src="https://img.icons8.com/dotty/34/000000/money-bag-euro.png"
                alt="Payment Details"
                className="px-2"
              />{" "}
              Payment Details{" "}
            </a>
          </li>

          <li
            className={
              location?.pathname === "/change_password" ? "active" : null
            }>
            <a href="/change_password">
              {" "}
              <img
                src="https://img.icons8.com/ios/34/000000/settings--v2.png"
                alt="Settings"
                className="px-2"
              />{" "}
              Settings{" "}
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;

import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Graph from "../../Components/Dashboard/Graph";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Analytics from "../../Components/Dashboard/Analytics";
import RecentActivities from "../../Components/Dashboard/RecentActivities";

const Dashboard = () => {
  return (
    <>
      <div className="page">
        <Header />

        <div className="page-content d-flex align-items-stretch">
          <Sidebar />

          <div className="content-inner">
            <header className="page-header">
              <div className="container-fluid">
                <h2 className="no-margin-bottom">Dashboard</h2>
              </div>
            </header>

            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink to="/dashboard">Home</NavLink>
                </li>
              </ul>
            </div>

            <Analytics />

            <Graph />

            <RecentActivities />

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

import React from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Sidebar from "../../Components/Sidebar/Sidebar";

const ActiveBoost = () => {
  return (
    <div className="page">
      {/* Main Navbar*/}

      <Header />

      <div className="page-content d-flex align-items-stretch">
        {/* Side Navbar */}
        <Sidebar />

        <div className="content-inner">
          {/* Page Header*/}
          <header className="page-header">
            <div className="container-fluid">
              <h2 className="no-margin-bottom">Details</h2>
            </div>
          </header>
          {/* Forms Section*/}
          <section className="forms">
            <div className="container-fluid">
              <div className="row">
                {/* Form Elements */}
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard5"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle">
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          aria-labelledby="closeCard5"
                          className="dropdown-menu dropdown-menu-right has-shadow">
                          <a href="#/" className="dropdown-item remove">
                            {" "}
                            <i className="fa fa-times" />
                            Close
                          </a>
                          <a href="#/" className="dropdown-item edit">
                            {" "}
                            <i className="fa fa-gear" />
                            Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-header d-flex align-items-center">
                      <h3 className="h4"> Details </h3>
                    </div>
                    <div className="card-body">
                      <form className="form-horizontal">
                        <div className="line" />
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Amount willing to pay for boosting
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="number"
                              name="industry"
                              className="form-control"
                              placeholder="boosting"
                            />
                          </div>
                        </div>
                        <div className="line"> </div>
                        <div className="row">
                          <label className="col-sm-3 form-control-label">
                            Product Details
                          </label>
                          <div className="col-sm-9">
                            <div className="form-group-material">
                              <input
                                id="register-username"
                                type="text"
                                name="registerUsername"
                                required
                                className="input-material"
                              />
                              <label
                                htmlFor="register-username"
                                className="label-material">
                                Product Name
                              </label>
                            </div>
                            <div className="form-group-material">
                              <input
                                id="register-"
                                type="text"
                                name="registerUsername"
                                required
                                className="input-material"
                              />
                              <label
                                htmlFor="register-"
                                className="label-material">
                                Product Reference Number
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="line" />
                        <div className="form-group row">
                          <div className="col-sm-4 offset-sm-3">
                            <a
                              href="/Payment"
                              type="submit"
                              className="btn btn-primary">
                              Proceed to checkout
                            </a>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Page Footer*/}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ActiveBoost;

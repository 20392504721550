import axios from "axios";
import { Toast } from "primereact/toast";
import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";
import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

const ActiveProducts = () => {
  const toast = useRef(null);
  const [token, setToken] = useState(null);
  const [active, setActive] = useState([]);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_url}/product/list_active_product/`, {
          headers: {
            "X-COMPANY-USER-AUTH": token,
          },
        })
        .then((res) => {
          // console.log(res.data);
          setActive(res.data.active_post || res.data.product_post);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  const accept = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_Base_url}/product/add_trend/`,
        {
          product_id: id,
        },
        {
          headers: {
            "X-COMPANY-USER-AUTH": token,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        toast.current.show({
          severity: "info",
          summary: "Confirmed",
          detail: `${res.data.message} `,
          life: 3000,
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const confirm1 = (id) => {
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        accept(id);
      },
      reject,
    });
  };

  // const handleBoost = (id) => {
  //   console.log(id);
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_Base_url}/product/add_trend/`,
  //       {
  //         product_id: id,
  //       },
  //       {
  //         headers: {
  //           "X-COMPANY-USER-AUTH": token,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log(res.data);
  //       alert(res.data.message);
  //       window.location.reload(true);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="page">
        {/* Main Navbar*/}
        <Header />

        <div className="page-content d-flex align-items-stretch">
          {/* Side Navbar */}
          <Sidebar />

          <div className="content-inner">
            {/* Page Header*/}
            <header className="page-header">
              <div className="container-fluid">
                <h2 className="no-margin-bottom">Active Dashboard</h2>
              </div>
            </header>
            {/* TABLES */}
            <section className="tables">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-close">
                        <div className="dropdown">
                          <button
                            type="button"
                            id="closeCard3"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="dropdown-toggle">
                            <i className="fa fa-ellipsis-v" />
                          </button>
                          <div
                            aria-labelledby="closeCard3"
                            className="dropdown-menu dropdown-menu-right has-shadow"></div>
                        </div>
                      </div>
                      <div className="card-header d-flex align-items-center">
                        <h3 className="h4">Active Product Post </h3>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-striped table-hover">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Product Name</th>
                                <th>Product Category</th>
                                <th>Item(s) in Stock</th>
                                <th>Boost Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            {active.length !== 0 &&
                            active.length !== undefined ? (
                              <tbody>
                                {active &&
                                  active.map((product) => (
                                    <tr key={product.product_uuid}>
                                      <th scope="row">#</th>
                                      <td>{product.product_name}</td>
                                      <td>
                                        {product.category.product_category_name}
                                      </td>
                                      <td>{product.stock_number}</td>

                                      <td>
                                        {product.boosted === "Active" ? (
                                          <span className="btn btn-success">
                                            {product.boosted}
                                          </span>
                                        ) : (
                                          <>
                                            {product.boosted === "Pushed" ? (
                                              <button className="btn btn-warning">
                                                {product.boosted}
                                              </button>
                                            ) : (
                                              <button className="btn btn-danger">
                                                {product.boosted}
                                              </button>
                                            )}
                                          </>
                                        )}
                                      </td>

                                      <td>
                                        <button
                                          onClick={() =>
                                            confirm1(product.product_uuid)
                                          }
                                          type="button"
                                          className="btn btn-primary">
                                          Boost
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <th></th>
                                  <td></td>
                                  <td className="py-3">No Records Found</td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Page Footer*/}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveProducts;
